import { useTranslation } from 'react-i18next';

import {Trash} from "../../icons";

const useBanksTable = ({ onDelete }) => {
  const { t } = useTranslation()

  const getColumns = () => [
    {
      field: 'bankName',
      flex: 2,
      minWidth: 200,
      headerName: t('Bank Name'),
    },
    {
      field: 'address',
      flex: 2,
      minWidth: 200,
      headerName: t('Address'),
    },
    {
      field: 'id',
      flex: 1,
      minWidth: 100,
      headerName: t('Actions'),
      getActions: (params) => [
        {
          sx: { color: 'light.black' },
          key: `Delete${params.id}`,
          Icon: Trash,
          iconProps:{
            width: '0.7em',
            height: '0.7em',
          },
          onClick: (e) => {
            e.stopPropagation()
            if (onDelete) {
              onDelete(params.id)
            }
          }
        },
      ],
    },
  ]
  return { banksColumns: getColumns() }
}

export default useBanksTable
