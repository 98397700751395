import {useEffect} from "react";
import {useSelector} from "react-redux";

import {LoadingButton} from "@mui/lab";

import {useModal, useTableSearch} from "../../hooks";
import useStatisticsTable from "../../services/statistics/statisticsTableService";
import {Trash} from "../../icons";
import {TableWithWrapper, TableHeader, TranslationTypography, PageWrapper, ConfirmModal} from "../../components";
import useItem from "../../services/item/itemService";
import useTables from "../../services/tables/tablesService";

export default function StatisticsPage () {
    const styles = {
        headerWrapper: {
            justifyContent: 'space-between'
        }
    }
    const lastUpdated = useSelector(state => state.table.lastUpdated);

    const [ getStatisticsData, setStatisticsTableSettings,, tableLastUpdated ] = useTables("/stats");
    const { deleteItemData } = useItem();

    const {searchValue, setSearchValue} = useTableSearch();
    const [open, handleOpen, handleClose] = useModal();

    const onSubmitDelete = async () => {
        const response = await deleteItemData({url:"/stats"});
            if (response) {
                tableLastUpdated()
            }
        return response
    }

    useEffect(()=>{
        getStatisticsData()
    }, [lastUpdated])

    const { statisticsColumns } = useStatisticsTable();

    return(
        <PageWrapper>
            <TranslationTypography text="statistics.title" variant='h3'/>
            <TableHeader
                value={searchValue}
                onChange={setSearchValue}
                sx={styles.headerWrapper}
                showAddNew={false}
            >
                <LoadingButton onClick={handleOpen} color="secondary" startIcon={<Trash/>}>
                    <TranslationTypography text="Clear Log" variant='h6'/>
                </LoadingButton>
            </TableHeader>
            <TableWithWrapper
                columns={statisticsColumns}
                onTableSettingsChange={setStatisticsTableSettings}
                rowHover
            />
            <ConfirmModal
                title='statistics.clear'
                onClose={handleClose}
                open={open}
                onSubmit={onSubmitDelete}
            />
        </PageWrapper>
    )
};