import { createAsyncThunk } from '@reduxjs/toolkit';
import API from "../../HOCs/InterceptorsProvider";

const getTags = createAsyncThunk('GET_TAGS', async ({ url }, { getState }) => {
    try {
      const response = await API.get(url)
      if (response?.data) {
        return response.data
      }
    } catch (e) {
      console.log(e)
    }

    const state = getState();
  return state?.tags?.tags
});

export {getTags};
