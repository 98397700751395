import {Edit, Visualize, VisualizeNew} from "../icons";

const EDIT = {
    color: 'primary',
    variant: 'contained',
    startIcon: <Edit />,
    text:'Edit'
};

const VISUALIZE = {
    color: 'secondary',
    startIcon: <Visualize />,
    text:'Visualize'
};

const VISUALIZE_NEW = {
    color: 'secondary',
    startIcon: <VisualizeNew />,
    text:'Visualize New'
};

export {EDIT, VISUALIZE, VISUALIZE_NEW}