import * as React from "react";

function Visualize(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 16" fill="none" {...props}>
      <path
        d="M1 8s3.636-7 10-7 10 7 10 7-3.636 7-10 7S1 8 1 8z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 11a3 3 0 100-6 3 3 0 000 6z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoVisualize = React.memo(Visualize);
export default MemoVisualize;
