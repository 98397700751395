import { useDispatch } from 'react-redux';

import {setLastUpdated, resetTagsState} from "../../redux/slices/tagsSlice";
import {getTags} from "../../redux/asyncServices/tagsService";

function useTags(path="/") {
  const dispatch = useDispatch();

  const tagsLastUpdated = () => dispatch(setLastUpdated(Date.now()));
  const setResetTagsState = () => dispatch(resetTagsState());

  const getTagsData = (url = null) => dispatch(getTags({ url: url || `tags${path}` }));

  return [
    getTagsData,
    setResetTagsState,
    tagsLastUpdated,
  ]
}

export default useTags;
