import { useTranslation } from 'react-i18next';
import {toFrontendDateFormat} from "../utils/helpers";

function useTableCellRender() {
  const { t } = useTranslation()

  const renderDate = (date, format) => toFrontendDateFormat(date, format)

  return {
    renderDate
  }
}

export default useTableCellRender
