import { useController } from 'react-hook-form';

import {
    FormControl,
    FormHelperText,
    FormLabel,
    OutlinedInput,
    MenuItem,
    Select,
} from '@mui/material';

export default function FormSelect({
                                         id = null,
                                         label = '',
                                         name,
                                         errors,
                                         control,
                                         placeholder = '',
                                         items = [],
                                         ...rest
                                     }) {

    function getStyles(currentName, personName) {
        return {
            fontWeight: personName.indexOf(currentName) === -1 ? 300 : 400,
        };
    }

    const {
        field: { onChange, value, ...controllerProps },
    } = useController({
        name,
        control,
        defaultValue: false,
    })

    const props = { ...controllerProps, ...rest };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const styles = {
        error:{
            position: 'absolute',
            width: '300px',
            top: '30px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        formControl:{
                marginLeft: 0,
                marginBottom: '25px'
        },
        label: {
            color: "light.black",
            fontSize: '0.875rem',
            fontWeight: 700
        },
        input: {
            bgcolor: 'light.white',
            "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                    border: "none"
                }
            }
        }
    };
    return (
        <FormControl sx={styles.formControl} variant="outlined">
            {!!label && <FormLabel sx={styles.label} id={`select-form-control-label-${label}`}>{label}</FormLabel>}
            <Select
                onChange={onChange}
                id={id || `select-${name}`}
                name={name}
                value={value}
                input={<OutlinedInput />}
                renderValue={(selected) => {

                    if (selected.length === 0 ) {
                            return <em>{placeholder}</em>;
                    }

                    if(Array.isArray(selected)){
                        return items.reduce(
                            (previousValue, currentValue)=> (
                                selected.includes(currentValue.value) ?
                                    [...previousValue, currentValue.label]
                                    : previousValue)
                            , []).join(', ');
                    }

                    const selectedItem = items.find(item => item.value === selected);
                   return selectedItem?.label
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
                sx={styles.input}
                {...props}
            >
                <MenuItem disabled value="">
                    <em>{placeholder}</em>
                </MenuItem>
                {items.map((item) => (
                    <MenuItem
                        key={item?.value}
                        value={item?.value}
                        style={getStyles(name, value)}
                    >
                        {item?.label}
                    </MenuItem>
                ))}
            </Select>
            {errors && (
                <FormHelperText sx={styles.error} error id={`select-error-${name}`}>
                    {errors?.message}
                </FormHelperText>
            )}
        </FormControl>
    )
};
