import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import {Box} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { termsOfService, privacyPolicy } from "../../../constants";

import { Form, TransTypography } from '../../../components'

export default function RegisterForm(props) {

  const { t } = useTranslation();

    const {onSubmitForm, loading = false} = props;

  const schema = yup.object().shape({
      name: yup.string().required(t('required')),
      email: yup.string().required(t('required')).email(),
      password: yup.string().required(t('required')),
      accept: yup.boolean().oneOf([true], t('checked'))
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
        name: props?.name ?? "",
        email: props?.email ?? "",
        password: props?.password ?? "",
        accept: props?.accept ?? false
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
      const response = await onSubmitForm(values);
      if(response){
          reset()
      }
  };

  const styles = {
      checkboxArea:{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px'
      },
      checkboxText: {
          marginBottom: '25px'
      },
      link:{
          color: '#EB3223',
          textDecoration: "none",
          fontSize: '12px',
          fontWeight: 400,
      },
      input: {
          borderRadius: '3px',
          bgcolor: 'primary.backgroundColor',
          "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                  border: "none"
              }
          }
      },
      checkbox:{
          color: "transparent",
          padding: 0,
          bgcolor: 'primary.backgroundColor',
          borderRadius: 0,
      },
  };

  return (
      <Form.FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Form.FormInput
            id="register-name"
            name="name"
            control={control}
            size="small"
            errors={errors.name}
            placeholder={t('Full name')}
            sx={styles.input}
        />
        <Form.FormInput
            id="register-email"
            name="email"
            control={control}
            size="small"
            errors={errors.email}
            placeholder={t('Email')}
            sx={styles.input}
        />
        <Form.FormInputPassword
            id="register-password"
            name="password"
            control={control}
            size="small"
            errors={errors.password}
            placeholder={t('Password')}
            sx={styles.input}
        />
        <Box
            component="div"
            sx={styles.checkboxArea}
        >
            <Form.FormCheckbox
                id="register-accept"
                name="accept"
                control={control}
                errors={errors.accept}
                sx={styles.checkbox}
            />
            <TransTypography i18nKey="login.registerCheckboxText" variant="body3" sx={styles.checkboxText}>
                I accept the <a
                href={termsOfService.url}
                rel="noreferrer"
                target="_blank"
                style={styles.link}
            >{termsOfService.label}</a> and <a
                href={privacyPolicy.url}
                target="_blank"
                rel="noreferrer"
                style={styles.link}
            >{privacyPolicy.label}</a>
            </TransTypography>
        </Box>
        <LoadingButton loading={loading} type='submit' color="secondary">
            {t('Register')}
        </LoadingButton>
      </Form.FormWrapper>
  )
};
