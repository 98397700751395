import { useController } from 'react-hook-form';

import {
    FormControl,
    FormHelperText,
    FormControlLabel,
    RadioGroup, Radio, FormLabel
} from '@mui/material';

export default function FormRadioGroup({
                                         id = null,
                                         label = '',
                                         name,
                                         errors,
                                         control,
                                         items = [],
                                         ...rest
                                     }) {
    const {
        field: { onChange, value, ...controllerProps },
    } = useController({
        name,
        control,
        defaultValue: false,
    })

    const props = { ...controllerProps, ...rest };

    const styles = {
        error:{
            position: 'absolute',
            top: '25px',
            whiteSpace: 'nowrap'
        },
        formControl:{
            "& .MuiFormControlLabel-root":{
                marginLeft: 0,
                marginBottom: '25px'
            }
        },
        label: {
            paddingBottom: '1.5rem',
            color: "light.black",
            fontSize: '0.875rem',
            fontWeight: 700
        },
        radioLabel:{
            color: "light.black",
            fontSize: '0.875rem',
            fontWeight: 400
        },
        radio:{
            color: 'transparent',
            border: 'none',
            '& span svg':{
                backgroundColor: 'primary.backgroundColor',
                borderRadius: '50%'
            },
            '&.Mui-checked': {
                color: 'secondary.main',
            },
        }
    };

    return (
        <FormControl sx={styles.formControl} variant="outlined">
            <FormLabel sx={styles.label} id={`radio-group-form-control-label-${label}`}>{label}</FormLabel>
            <RadioGroup
                id={id || `radio-group-${name}`}
                name={name}
                value={value}
                onBlur={onChange}
                onChange={onChange}
                {...props}
            >
                {items.map(item=>(<FormControlLabel sx={styles.radioLabel} key={item.value} value={item.value} control={<Radio sx={styles.radio} disableRipple/>} label={item.label} />))}
            </RadioGroup>
            {errors && (
                <FormHelperText sx={styles.error} error id={`error-${name}`}>
                    {errors?.message}
                </FormHelperText>
            )}
        </FormControl>
    )
};
