import {createAsyncThunk} from "@reduxjs/toolkit";
import API from "../../HOCs/InterceptorsProvider";
import {isResponseOk} from "../../utils/helpers";

export const requestCount = createAsyncThunk('REQUEST_COUNT', async ({params = null }) => {
    try {
        const response = await API.get('registration/requests', {params});
        if (isResponseOk(response) &&  response.data?.totalElements) {
            return response.data.totalElements;
        }
    } catch (error) {
        console.warn(error.message)
    }
    return 0;
});