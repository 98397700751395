import { useController } from 'react-hook-form';

import { TextField, FormHelperText, FormControl } from '@mui/material';

export default function FormInput({
  id = null,
  label = '',
  name,
  control,
  type,
  size,
  errors = '',
  ...rest
}) {

  const {
    field: { ref, onChange, onBlur, ...controllerProps },
  } = useController({
    name,
    control,
    defaultValue: '',
  });

  const props = { ...controllerProps, ...rest };

  const styles = {
      formControl:{
          marginBottom: '25px'
      },
      error:{
          position: 'absolute',
          top: '32px',
          width: '300px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
      },
      input: {
          borderRadius: '3px',
          bgcolor: 'light.white',
          "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                  border: "none"
              }
          }
      },
  };

  return (
    <FormControl fullWidth sx={styles.formControl} variant="outlined">
      <TextField
          id={id || `input-${name}`}
          inputRef={ref}
          onBlur={onBlur}
          type={type}
          size={size}
          onChange={onChange}
          error={!!errors}
          label={label}
          sx={styles.input}
          {...props}
      />
      {errors && (
        <FormHelperText sx={styles.error} error id={`error-${name}`}>
          {errors?.message}
        </FormHelperText>
      )}
    </FormControl>
  )
};
