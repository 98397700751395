import Typography from "@mui/material/Typography";
import { Trans, useTranslation } from 'react-i18next';

export default function TransTypography ({children, i18nKey="", ...rest}) {
    const { t } = useTranslation();
  return(
      <Typography {...rest}>
        <Trans t={t} i18nKey={i18nKey}>
            {children}
        </Trans>
      </Typography>
  )
};