import { createSlice } from '@reduxjs/toolkit';

import {getItem} from "../asyncServices/itemService";

const initialState = {
    selectedItem: {},
    lastUpdated: null,
    isLoading: 'initialLoading',
}

const itemSlice = createSlice({
    name: 'itemSlice',
    initialState,
    reducers: {
        setLastUpdated: (state, action) => {
            state.lastUpdated = action.payload
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload
        },
        resetItemState: () => ({...initialState})
    },
    extraReducers: (builder) => {
        builder.addCase(getItem.pending, (state) => {
            state.isLoading = 'getItem'
        })
        builder.addCase(getItem.fulfilled, (state, action) => {
            state.selectedItem = action.payload
            state.isLoading = null
        })
    },
})

export const { setLastUpdated, setLoading, resetItemState } = itemSlice.actions;
export default itemSlice.reducer;
