import {memo} from "react";

import {Box} from "@mui/material";

import AppModal from "./AppModal";
import ButtonTranslation from "./ButtonTranslation";
import TranslationTypography from "./translation-components/TranslationTypography";

function ConfirmModal({title = '', text = 'modal.sure', onSubmit, onClose, ...rest}){
    const styles = {
        content: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '2rem',
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            columnGap: '1rem',
            paddingTop: '2rem'
        }
    }

    const handleSubmit = async () => {
        await onSubmit();
        onClose()
    }

    const handleClose = (e, reason) => !reason && onClose()

    return(
        <AppModal handleClose={handleClose} {...rest}>
            <>
                <Box
                    component='div'
                    sx={styles.content}
                >
                    {!!title && <TranslationTypography text={title} variant='h3'/>}
                    <TranslationTypography text={text} variant='h6'/>
                </Box>
                <Box
                    component='div'
                    sx={styles.footer}
                >
                    <ButtonTranslation onClick={handleClose} color="secondary" text="No"/>
                    <ButtonTranslation onClick={handleSubmit} color="primary" variant='contained' text="Yes"/>
                </Box>
            </>
        </AppModal>
    )
}

export default memo(ConfirmModal)