import { useController } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from '@mui/material';

export default function FormCheckbox({
  id = null,
  label = '',
  name,
  errors,
  control,
  ...rest
}) {
  const {
    field: { onChange, value, ...controllerProps },
  } = useController({
    name,
    control,
    defaultValue: false,
  })

  const props = { ...controllerProps, ...rest };

  const styles = {
    error:{
      position: 'absolute',
      top: '25px',
      whiteSpace: 'nowrap'
    },
    formControl:{
      "& .MuiFormControlLabel-root":{
        marginLeft: 0,
        marginBottom: '25px'
      }
    }
  };

  return (
    <FormControl sx={styles.formControl} variant="outlined">
      <FormControlLabel
        control={
          <Checkbox
              color="secondary"
              id={id || `checkbox-${name}`}
              name={name}
              checked={value}
              onBlur={onChange}
              onChange={onChange}
              {...props}
          />
        }
        label={label}
      />
      {errors && (
        <FormHelperText sx={styles.error} error id={`error-${name}`}>
          {errors?.message}
        </FormHelperText>
      )}
    </FormControl>
  )
};
