import {memo} from "react";
import {useTranslation} from "react-i18next";
import {Box, Button} from "@mui/material";
import {LoadingButton} from "@mui/lab";

function FormButtons({onCancel, loading = false}) {
    const styles = {
        footer: {
            display: 'flex',
            justifyContent: 'space-between',
            columnGap: '1rem'
        },
        button: {
            margin: '2rem 0',
        }
    };

    const { t } = useTranslation();

    return (
        <Box
            component="div"
            sx={styles.footer}
        >
            <Button disabled={loading} onClick={onCancel} color="secondary" sx={styles.button}>
                {t('Cancel')}
            </Button>
            <LoadingButton
                loading={loading}
                type='submit'
                color="primary"
                variant='contained'
                sx={styles.button}
            >
                {t('Save')}
            </LoadingButton>
        </Box>
    )
}

export default memo(FormButtons)