import { useController } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  Box,
  Switch, FormLabel, styled
} from '@mui/material';

export default function FormSwitch({
  id = null,
  label = '',
  name,
  errors,
  control,
  formControlStyles = {},
  ...rest
}) {

  const styles = {
    label: {
      paddingBottom: '1.5rem',
      color: "light.black",
      fontSize: '0.875rem',
      fontWeight: 700,
      "&.Mui-focused":{
        color: "light.black",
      }
    },
    switchLabel:{
      color: "light.black",
      fontSize: '0.875rem',
      fontWeight: 400
    },
  }

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 57,
    height: 28,
    padding: 0,
    display: 'flex',
    borderRadius: "100px",

    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 20,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: '5px',
      color: '#BCBCBC',
      height: "100%",
      '&.Mui-checked': {
        transform: 'translateX(28px)',
        color: '#EB3223',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: "#F5F5F5",
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 16,
      height: 16,
      transition: theme.transitions.create(['width'], {
        duration: 500,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: "#F5F5F5",
      boxSizing: 'border-box',
    },
  }));

  const {
    field: { onChange, value, ...controllerProps },
  } = useController({
    name,
    control,
    defaultValue: false,
  })

  const props = { ...controllerProps, ...rest }

  return (
    <FormControl sx={formControlStyles} variant="outlined">
      <FormLabel sx={styles.label} id={`switch-form-control-label-${label}`}>{label}</FormLabel>
      <AntSwitch
          id={id || `switch-${name}`}
          name={name}
          checked={value}
          onBlur={onChange}
          onChange={onChange}
          color='secondary'
          {...props}
      />
      {errors ? (
        <FormHelperText sx={{ minHeight: '25px' }} error id={`error-${name}`}>
          {errors?.message}
        </FormHelperText>
      ) : (
        <Box sx={{ minHeight: '25px' }} />
      )}
    </FormControl>
  )
};
