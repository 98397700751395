import {
  TableRow,
  TableCell,
  Checkbox,
  TableBody
} from '@mui/material';

import AppTableBodyCell from './AppTableBodyCell'

export default function AppTableBody(props) {
  const {
    columns,
    rows,
    handleClickRow,
    isErrorValueRow,
    isSelected,
    selected,
    rowHover
  } = props;

  const styles = {
    row: {
      '& .MuiTableRow-hover': {
        bgcolor: 'transparent'
      }
    }
  }

  return (
    <TableBody>
      {rows.map((row, index) => {
        const isItemSelected = isSelected(row.id)
        const labelId = `enhanced-table-checkbox-${index}`;
        const changeWarning = () => {
          if (isErrorValueRow && row.isEditPrice) {
            return isErrorValueRow
          }
          if (!row.isEditPrice) {
            return row.isWarning
          }
          return null
        }
        return (
          <TableRow
              hover={rowHover}
            sx={{
              bgcolor: changeWarning(row) ? '#FFCCCC' : (index % 2 === 0) && 'primary.backgroundColor',
            }}
            onClick={(event) => handleClickRow(event, row.id)}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}>
            {selected && (
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </TableCell>
            )}
            {columns.map((column) => (
              <AppTableBodyCell
                key={row.id + column.field}
                row={row}
                column={column}
              />
            ))}
          </TableRow>
        )
      })}
    </TableBody>
  )
}
