import { createSlice } from '@reduxjs/toolkit'
import {authLogin} from '../asyncServices/authService'

const initialState = {
  isAuth: false,
  user: {},
  isLoading: null,
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(authLogin.pending, (state) => {
      state.isLoading = 'authLogin'
    })
    builder.addCase(authLogin.fulfilled, (state, action) => {
      state.isAuth = action.payload.isAuth
      state.user = action.payload.user
      state.isLoading = null
    })
  },
})

export const { setLoading } = authSlice.actions;
export default authSlice.reducer
