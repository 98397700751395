import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {Avatar, Box, Typography} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";

import useAuth from "../../services/authService";

import BasicMenu from "../BasicMenu";

export default function AdminHeaderMenu(){
    const styles = {
        wrapper: {
            display: "flex",
            alignItems: "center",
            columnGap: '1rem'
        },
        avatar: {
            bgcolor: 'primary.main'
        }
    };

    const navigate = useNavigate();
    const email = useSelector((state) => state.auth.user.email);

    const { logout } = useAuth();

    const menuItems = [
        {
            label: "Settings",
            value: "settings",
            onClick: ()=> navigate('/settings')
        },
        {
            label: "Logout",
            value: "logout",
            onClick: ()=> logout()
        },
    ];

    return(
        <BasicMenu menuItems={menuItems}>
            <Box
                component="div"
                sx={styles.wrapper}
            >
                <Avatar sx={styles.avatar}>{email && email[0].toUpperCase()}</Avatar>
                <Typography variant='body1'>
                    {email}
                </Typography>
                <KeyboardArrowDown />
            </Box>
        </BasicMenu>
    )
}