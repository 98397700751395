import {Box} from "@mui/material";

import {RowDataItem} from "../../../components";
import {toFrontendDateFormat} from "../../../utils/helpers";
import {statusItems} from "../../../constants";

export default function CompanyRowData(company){
    const styles = {
        wrapper: {
            display: 'grid',
            maxWidth: '100%',
            gridTemplateColumns: 'repeat(4, auto)',
            columnGap: '0.5rem',
            alignItems: 'center',
            rowGap: '1rem',
            paddingY: '2.5rem',
        },
        item: {
            gridTemplateColumns: 'auto 1fr',
            columnGap: '0.5rem',
        }
    }
    return(
        <Box
            component='div'
            sx={styles.wrapper}>
            <RowDataItem field='Name' text={company?.name} sx={styles.item}/>
            <RowDataItem field='Creation date' text={toFrontendDateFormat(company?.creationDate)} sx={styles.item}/>
            <RowDataItem field='Address' text={company?.address} sx={styles.item}/>
            <RowDataItem field='Coordinates' text={company?.coordinates} sx={styles.item}/>
            <RowDataItem field='Rating' text={company?.rating} sx={styles.item}/>
            <RowDataItem field='Selfshare (%)' text={company?.selfShare} sx={styles.item}/>
            <RowDataItem field='Status' text={statusItems[company?.status] ?? ""} sx={styles.item}/>
            <RowDataItem field='Compliance' text={Array.isArray(company?.compliance) && company.compliance.join(', ')} sx={styles.item}/>
        </Box>
    )
}