import { useState } from 'react';
import {
    IconButton,
    Box,
    Menu
} from '@mui/material';

import { MoreVert } from '@mui/icons-material';

function MobileMenu({ children }) {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl)

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleMobileMenuOpen = (e) => {
    e.stopPropagation()
    setMobileMoreAnchorEl(e.currentTarget)
  }

  const mobileMenuId = 'account-menu-mobile'
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      {children}
    </Menu>
  )

  return (
    <Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>{children}</Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <IconButton
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit">
          <MoreVert />
        </IconButton>
      </Box>
      {renderMobileMenu}
    </Box>
  )
}

export default MobileMenu
