import {memo} from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";

import {LoadingButton} from "@mui/lab";

import {Form} from "../../../components";
import {withLoading} from "../../../HOCs";

function SettingsForm(props){
    const styles = {
        form:{
            display: 'flex',
            flexDirection: 'column',
            padding: '2.5rem 0'
        },
    };

    const { t } = useTranslation();

    const {onSubmitForm, loading = false} = props;

    const schema = yup.object().shape({
        arrowStyle: yup.string().required(t('required')),
        showRatingWithoutRequest: yup.boolean().required(t('required')),
        denyMeToSeeRating: yup.boolean().required(t('required')),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues:{
            arrowStyle: props?.arrowStyle ?? 'filled',
            showRatingWithoutRequest: props?.showRatingWithoutRequest ?? true,
            denyMeToSeeRating: props?.denyMeToSeeRating ?? false,
        },
        resolver: yupResolver(schema),
    });

    return(
        <Form.FormWrapper
            onSubmit={handleSubmit(onSubmitForm)}
            sx={styles.form}
        >
            <Form.FormRadioGroup
                id="settings-arrowStyle"
                name="arrowStyle"
                label="Arrow style"
                control={control}
                errors={errors.arrowStyle}
                row
                items={[{label:'Filled', value: 'filled'},{label:'Unfilled', value: 'unfilled'}]}
            />
            <Form.FormSwitch
                id="settings-showRatingWithoutRequest"
                name="showRatingWithoutRequest"
                label="Show rating by default"
                control={control}
                errors={errors.showRatingWithoutRequest}
            />
            <Form.FormSwitch
                id="settings-denyMeToSeeRating"
                name="denyMeToSeeRating"
                label="Deny me to see rating"
                control={control}
                errors={errors.denyMeToSeeRating}
            />
            <LoadingButton
                loading={loading}
                type='submit'
                variant="contained"
                color="secondary"
            >
                {t('Save')}
            </LoadingButton>
        </Form.FormWrapper>
    )
}

export default memo(withLoading(SettingsForm))