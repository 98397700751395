import { Outlet } from 'react-router-dom'

import { Box } from '@mui/material';
import Header from "./Header";
import Footer from "./Footer";

export default function Layout() {

    const styles = {
        wrapper: {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            width: "100%",
            maxHeight: "100vh",
            height: "100vh"
        },
        mainWrapper: {
            display: 'flex',
            justifyContent: 'center',
            flex: "1 0 auto",
        },
        footerWrapper: {
            flex: "0 0 auto",
            bgcolor: 'primary.main',
            color: 'light.white'
        }
    };

    return (
            <Box
                component="div"
                sx={styles.wrapper}>
                <Header/>
                <Box
                    component="main"
                    sx={styles.mainWrapper}>
                    <Outlet />
                </Box>
                <Box
                    component="footer"
                    sx={styles.footerWrapper} >
                    <Footer/>
                </Box>
            </Box>
    )
};
