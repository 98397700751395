import { useState } from 'react';

import { Box, MenuItem, Menu } from "@mui/material";

export default function BasicMenu({menuItems, children}) {
    const styles = {
        componentWrapper:{
            color: 'primary.main',
            background: "transparent"
        }
    };

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box component='div'>
            <Box
                sx={styles.componentWrapper}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {children}
            </Box>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                PaperProps={{
                    style: {
                        minWidth: '15ch',
                    },
                }}
            >
                {menuItems.map(({onClick, label,  ...menuItem}) => {
                    const handleItemClick = () => {
                        if(typeof onClick === "function"){
                            onClick()
                        }
                        handleClose()
                    };

                    return (<MenuItem key={`${menuItem.value}-menu-item`} onClick={handleItemClick} {...menuItem}>{label}</MenuItem>)
                })}
            </Menu>
        </Box>
    );
}