import { combineReducers } from '@reduxjs/toolkit';
import {persistReducer} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { LOG_OUT } from '../constants';
import authReducer from './slices/authSlice';
import commonReducer from './slices/commonSlice';
import notificationReducer from './slices/notificationSlice';
import tableReducer from './slices/tableSlice';
import itemReducer from './slices/itemSlice';
import tagsReducer from './slices/tagsSlice';

const rootPersistConfig = {
  key: 'trace',
  storage,
};

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['isLoading']
}

const appReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  common: commonReducer,
  notification: notificationReducer,
  table: tableReducer,
  item: itemReducer,
  tags: tagsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    const { common } = state;
    state = { common };
    storage.removeItem('persist:trace');
    storage.removeItem('persist:auth');
    return appReducer(state, action);
  }
  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
