import {termsOfService, privacyPolicy, imprint} from "./links";
import * as DEMO_DATA from './demoData';
import * as TABS_DATA from "./tabsData";
import * as FORMS_DATA from "./formsData";
import * as ROLES from './roles';
import * as PAGES from './pages';
import * as BUTTONS from './buttons';

export const LOG_OUT = 'LOG_OUT';
export const en = 'en';

export const complianceItems = [
    {label: 'RCA', value: 'RCA'},
    {label: 'SIP', value: 'SIP'},
    {label: 'PEP', value: 'PEP'}
];

export const genderItems = [
    {label: 'Male', value: 'Male'},
    {label: 'Female', value: 'Female'}
];

export const personStatusItems = {
    active: 'Active',
    inactive: 'Inactive',
}

export const statusItems = {
    ...personStatusItems,
    privateFoundation: 'Private foundation',
};

export const phoneRegex = /^[+][0-9]{12}$/;
export const passwordRegexp = /^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)[0-9a-zA-Z]{8,}$/;

export {termsOfService, privacyPolicy, imprint, DEMO_DATA, TABS_DATA, ROLES, PAGES, BUTTONS, FORMS_DATA};