import * as React from "react";

function Wrench(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 21 21" fill="none" {...props}>
      <path
        d="M12.701 5.304a1 1 0 000 1.4l1.6 1.6a1 1 0 001.4 0l3.77-3.77a6 6 0 01-7.94 7.94l-6.91 6.91a2.121 2.121 0 11-3-3l6.91-6.91a6 6 0 017.94-7.94l-3.76 3.76-.01.01z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoWrench = React.memo(Wrench);
export default MemoWrench;
