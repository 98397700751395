import * as React from "react";

function Trash(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 19" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.53 1c-.224 0-.53.24-.53.7V2h3v-.3c0-.46-.306-.7-.53-.7H6.53zM5 1.7V2H.5a.5.5 0 000 1h1l-.5.028.74 13.017C1.749 17.442 3 18.5 4.44 18.5h6.12c1.427 0 2.691-1.045 2.7-2.455L14 3.028 13.5 3h1a.5.5 0 000-1H10v-.3C10 .84 9.386 0 8.47 0H6.53C5.614 0 5 .84 5 1.7zM13 3H2l.74 13.002v.028c0 .755.705 1.47 1.7 1.47h6.12c.988 0 1.7-.708 1.7-1.47v-.028L13 3zM5.5 5a.5.5 0 01.5.5v10a.5.5 0 01-1 0v-10a.5.5 0 01.5-.5zm4.5.5a.5.5 0 00-1 0v10a.5.5 0 001 0v-10z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoTrash = React.memo(Trash);
export default MemoTrash;
