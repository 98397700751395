import {memo} from "react";
import {Box} from "@mui/material";

function FormWrapper({onSubmit, children, sx={}}) {
    const styles = {
        wrapper:{
            display: 'flex',
            flexDirection: 'column',
            ...sx
        },
    }

    return (
        <Box
            component="form"
            onSubmit={onSubmit}
            sx={styles.wrapper}
        >
            {children}
        </Box>
    )
}

export default memo(FormWrapper)