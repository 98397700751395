import {memo} from "react";
import {useForm} from "react-hook-form";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";

import {LoadingButton} from "@mui/lab";

import {Form} from "../../../components";
import {withLoading} from "../../../HOCs";

function ProfileForm(props){
    const styles = {
        form:{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '1200px',
            minWidth: '350px',
            padding: '2.5rem 0'
        },
        input: {
            bgcolor: 'light.white',
        },
        button: {
            marginBottom: '2rem',
            marginTop: '1rem'
        }
    };

    const { t } = useTranslation();

    const {onSubmitForm, loading = false} = props;

    const schema = yup.object().shape({
        name: yup.string().required(t('required')),
        email: yup.string(),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm({
        defaultValues:{
            name: props?.name ?? '',
            email: props?.email ?? '',
        },
        resolver: yupResolver(schema),
    });

    return(
        <Form.FormWrapper
            onSubmit={handleSubmit(onSubmitForm)}
            sx={styles.form}
        >
            <Form.FormInput
                id="profile-name"
                name="name"
                control={control}
                size="small"
                errors={errors.name}
                placeholder={t('Name')}
                sx={styles.input}
            />
            <Form.FormInput
                id="profile-email"
                name="email"
                control={control}
                size="small"
                disabled
                errors={errors.email}
                placeholder={t('Email')}
                sx={styles.input}
            />
            <LoadingButton
                loading={loading}
                type='submit'
                variant="contained"
                color="secondary"
            >
                {t('Save')}
            </LoadingButton>
        </Form.FormWrapper>
    )
}

export default memo(withLoading(ProfileForm))