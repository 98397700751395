import {memo, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AppModal, ConfirmModal, TableHeader, TableWithWrapper} from "../../../components";
import useAdminUsersTable from "../../../services/admins/adminsTableService";
import useTables from "../../../services/tables/tablesService";
import useItem from "../../../services/item/itemService";
import useModal from "../../../hooks/useModal";
import AdminUserForm from "./AdminUserForm";
import {useTableSearch} from "../../../hooks";
import AdminAddUserForm from "./AdminAddUserForm";

function AdminUserTab({sx = {}}) {

    const [selectedId, setSelectId] = useState(null);

    const user = useSelector(state => state.item.selectedItem);
    const isLoading = useSelector(state => state.item.isLoading);
    const lastUpdated = useSelector(state => state.table.lastUpdated);

    const {searchValue, setSearchValue} = useTableSearch();
    const [open, handleOpen, handleClose] = useModal();
    const { getItemDataById, addItemData, updateItemData, deleteItemData, resetItem } = useItem("/admin/users");
    const [ getAdminUsersData, setAdminUsersTableSettings, , tableLastUpdated ] = useTables("/admin/users");

    const onDelete = async (id) => {
        handleOpen(null, 'delete')
        setSelectId(id)
    }

    const onEdit = async (id) => {
        handleOpen(null, 'edit')
        setSelectId(id)
    }

    const onAdd = async () => {
        handleOpen(null, 'add')
    }

    const onSubmitDelete = async () => {
        let response = null;
        if(selectedId) {
            response = await deleteItemData({id: selectedId})
            if(response) {
                tableLastUpdated()
            }
        }
        return response
    }

    const onCancel = async () => {
        handleClose()
        setSelectId(null)
    }

    const onSubmitForm = async (values) => {
        let response = null;

        if(open === 'edit') {
            response = await updateItemData({id: selectedId, data: {...values}})
        }

        if(open === 'add') {
           response = await addItemData({data: {...values}});
        }

        if (response){
            tableLastUpdated()
        }

        return response
    };

    useEffect(()=>{
        getAdminUsersData()
    }, [lastUpdated]);

    useEffect(()=>{
        if(selectedId) {
            getItemDataById({id: selectedId})
        }else {
            resetItem()
        }
    }, [selectedId]);

    const {adminsUsersColumns} = useAdminUsersTable({onEdit, onDelete});

    return(
        <>
            <TableHeader onClick={onAdd} value={searchValue} onChange={setSearchValue}/>
            <TableWithWrapper
                sx={sx}
                columns={adminsUsersColumns}
                onTableSettingsChange={setAdminUsersTableSettings}
            />
            <ConfirmModal
                title="modal.delete.user"
                open={(open === 'delete')}
                onSubmit={onSubmitDelete}
                onClose={onCancel}
            />
            <AppModal
                open={(open && open !== 'delete')}
                handleClose={onCancel}
                title={open === 'edit' ? "modal.edit.user" : "modal.new.user"}
            >
                {
                    open === 'edit' && (
                        <AdminUserForm
                            isLoading={(!!isLoading && !user?.id)}
                            {...user}
                            onSubmitForm={onSubmitForm}
                            onCancel={onCancel}
                        />)
                }
                {open === 'add' && <AdminAddUserForm onSubmitForm={onSubmitForm} onCancel={onCancel}/>}
            </AppModal>
        </>

    )
}

export default memo(AdminUserTab)