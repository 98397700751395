const LOGIN = 'login';
const RESET_PASSWORD = 'resetPassword';
const RESET_PASSWORD_CONFIRM = `${RESET_PASSWORD}/:id/:token`;
const DEMO = 'demo';
const PERSONS = 'persons';
const PERSON = `${PERSONS}/:id`;
const COMPANIES = 'companies';
const COMPANY = `${COMPANIES}/:id`;
const BANKS = 'banks';
const BANK = `${BANKS}/:id`;
const ABOUT = 'about';
const WIKI = 'wiki';
const STATISTICS = 'statistics';
const ADMIN = 'admin';
const SETTINGS = 'settings';

export {
    LOGIN, RESET_PASSWORD, RESET_PASSWORD_CONFIRM,
    ADMIN, WIKI, BANKS, ABOUT, COMPANIES, PERSONS, PERSON,
    STATISTICS, DEMO, SETTINGS, COMPANY, BANK
};