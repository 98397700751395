import moment from "moment";

import {Box} from "@mui/material";

import {termsOfService, privacyPolicy, imprint} from "../../constants";

import TransTypography from "../translation-components/TransTypography"

function Footer() {
    const styles = {
        wrapper:{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            alignItems: 'center',
            columnGap: '20px',
            padding: '1rem 10vw'
        },
        links: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            columnGap: '3rem',
            fontSize: '0.75rem',
            fontWeight: 600,
        },
        link: {
            textDecoration: "none",
            color: '#fff',
        }
    };

    const year = moment().format('YYYY');

  return (
      <Box
          component="div"
          sx={styles.wrapper}>
          <TransTypography variant="body6" i18nKey="footer.copyright">
              Copyright © {{year}} IVIS Solutions
          </TransTypography>
          <Box
              component="div"
              sx={styles.links}>
              {
                  [termsOfService, privacyPolicy, imprint].map(
                  (footerLink)=>(
                      <a
                          key={footerLink?.label}
                          href={footerLink?.url}
                          color="inherit"
                          rel="noreferrer"
                          target="_blank"
                          style={styles.link}
                      >{footerLink?.label}</a>
                  ))
              }
          </Box>
      </Box>
  )
}

export default Footer