import { useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Checkbox,
  ListItemText,
  Box
} from '@mui/material';

import { FilterAlt } from '@mui/icons-material';

import InputRange from '../InputRange';
import WithButton from '../WithButton';

const ITEM_HEIGHT = 48

export default function AppTableFilter(props) {
  const {
    filters,
    onClick,
    filterList,
    filterFrom,
    filterToo,
    onRequestFilter,
    tableSettings,
    filterName,
  } = props

  const [anchorEl, setAnchorEl] = useState(null)
  const [selected, setSelected] = useState(filters)

  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickItem = (value) => {
    if (typeof value === 'object') {
      const { property, values } = value
      if (values === null) {
        onRequestFilter(property, null)
        setSelected(filters)
      }
      onRequestFilter(property, values)
      setSelected(values)
    } else {
      const selectedIndex = selected.indexOf(value)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, value)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }
      setSelected(newSelected)
    }
  }

  const submitSelectedFilter = () => {
    onClick(selected)
    handleClose()
  }
  const clearSelectedFilter = () => {
    onClick([])
    setSelected([])
    handleClose()
  }

  const setOpacity = (isSelected, isOpen) => {
    if (isSelected) {
      return 1
    }
    if (isOpen) {
      return 0.5
    }
    return 0
  }
  return (
    <Box
      sx={{
        'opacity': setOpacity(selected.length, open),
        '&:hover': {
          opacity: 0.5,
        },
      }}>
      <IconButton sx={{ p: 1 }} size="small" onClick={handleClick}>
        <FilterAlt sx={{ width: 20, height: 30 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          style: {
            padding: 0,
            paddingBottom: 0,
          },
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: '20ch',
          },
        }}>
        {filterName !== null ? (
          <WithButton
            submitFilterValue={submitSelectedFilter}
            clearFilterValue={clearSelectedFilter}
            firstButtonTitle="Ok"
            secondButtonTitle="Clear">
            <Box>
              {filterList.map(({ label, value }) => (
                <MenuItem
                  key={value}
                  value={value}
                  sx={{
                    borderBottom: '1px solid #FFF',
                    borderTop: '1px solid #FFF',
                  }}
                  onClick={() => handleClickItem(value)}>
                  <Checkbox
                    checked={
                      Array.isArray(selected) && selected.includes(value)
                    }
                  />
                  <ListItemText primary={label} />
                </MenuItem>
              ))}
            </Box>
          </WithButton>
        ) : (
          <InputRange
            onClick={handleClickItem}
            filterTo={filterToo}
            handleClose={handleClose}
            filterFrom={filterFrom}
            tableSettings={tableSettings}
          />
        )}
      </Menu>
    </Box>
  )
}
