import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';

import {persistor, store} from './redux/store';
import theme from "./theme";

import { LanguageProvider } from './HOCs'
import {InterceptorsProvider} from "./HOCs/InterceptorsProvider";

import App from './App';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
      <Provider store={store}>
          <PersistGate persistor={persistor}>
            <LanguageProvider>
                <ThemeProvider theme={theme}>
                        <BrowserRouter>
                            <InterceptorsProvider>
                                <App />
                            </InterceptorsProvider>
                        </BrowserRouter>
                </ThemeProvider>
            </LanguageProvider>
          </PersistGate>
    </Provider>
);
