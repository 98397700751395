import {useDispatch} from 'react-redux';

import {getTableData} from "../../redux/asyncServices/tableService";
import {setLastUpdated, setSelectedItems, setTableSettings} from "../../redux/slices/tableSlice";

function useTables(path="/") {
  const dispatch = useDispatch();
  const tableLastUpdated = () => dispatch(setLastUpdated(Date.now()));

  const getTablesData = (params = {}) => dispatch(getTableData({url: path, params}));

  const selectedTablesItems = (data) => dispatch(setSelectedItems(data));

  const setTablesSettings = (params = {}) => {
    dispatch(setTableSettings(params))
    tableLastUpdated()
  };

  return [
    getTablesData,
    setTablesSettings,
    selectedTablesItems,
    tableLastUpdated,
  ]
}

export default useTables;
