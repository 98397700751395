export const termsOfService = {
    label: "Terms of Service",
    url: "http://www.ivis.at/terms-of-service/"
};

export const privacyPolicy = {
    label: "Privacy Policy",
    url: "http://www.ivis.at/privacy-policy/"
};

export const imprint = {
    label: "Imprint",
    url: "http://www.ivis.at/imprint/"
};