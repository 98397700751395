import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import { Box } from "@mui/material";

import {TABS_DATA} from "../../constants";
import {useModal, useTableSearch, useTabSearchParams} from "../../hooks";
import useItem from "../../services/item/itemService";

import BankRowData from "./components/BankRowData";
import {AppModal, PageHeader, PageWrapper, TableForms, TableHeader, TabList, TabTable} from "../../components";

import BankForm from "./components/BankForm";

export default function BankPage(){
    const styles = {
        tabList:{
            width: '100%',
            height: '48px',
            typography: 'body1',
            borderBottom: 1,
            borderColor: '#E7E4E4',
            paddingTop: '1rem'
        },
        tableWrapper: {
            maxHeight: '30vh',
            height: '30vh',
        }
    }

    const {id} = useParams();
    const bank = useSelector(state => state.item.selectedItem);
    const bankLastUpdated = useSelector(state => state.item.lastUpdated);

    const {tab, setTab} = useTabSearchParams(TABS_DATA.bankTabs);

    const {searchValue, setSearchValue} = useTableSearch();
    const { getItemDataById, updateItemData, resetItem } = useItem("/banks");

    const [open, handleOpen, handleClose] = useModal();

    const onEditSubmit = (values) => updateItemData({id, data:{...values}});

    useEffect(()=>{
        if(id){
            getItemDataById({id})
        }
    }, [id, bankLastUpdated])

    useEffect(()=>{
        if(searchValue) {
            setSearchValue("")
        }
    }, [tab])

    useEffect(()=>()=> resetItem(), []);

    return(
        <PageWrapper>
            <PageHeader {...bank} title='Bank' onEdit={(e)=>handleOpen(e, "edit")}/>
            <BankRowData {...bank}/>
            <Box
                component="div"
                sx={styles.tabList}
            >
                <TabList tabs={TABS_DATA.bankTabs} tab={tab} setTab={setTab}/>
            </Box>
            <TableHeader value={searchValue} onChange={setSearchValue} onClick={(e)=>handleOpen(e, tab)}/>
            {
                TABS_DATA.bankTabs.map(({table, value}) => (
                        tab === value && (
                                <TabTable
                                    key={`${value}-tab-panel-item`}
                                    tab={tab} table={table} value={value}
                                    url={`/banks/${id}`}
                                    path='/banks'
                                    sx={styles.tableWrapper}
                                />
                        ))
                )
            }
            <AppModal open={(!!bank?.id && !!open)} handleClose={handleClose} title={open === "edit" ? "modal.edit.bank" : `modal.${open}`}>
                {open === "edit" && <BankForm {...bank} onSubmitForm={onEditSubmit} onCancel={handleClose}/>}
                {open && open !== "edit" && <TableForms id={bank?.id} form={open} onCancel={handleClose} path="/banks"/>}
            </AppModal>
        </PageWrapper>
    )
}