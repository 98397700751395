import {forwardRef} from "react";
import { Link as RouterLink } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';

const AppLink = forwardRef((props, ref) => {
    const { href, ...other } = props;
    return <RouterLink ref={ref} to={href} {...other} />;
});

const theme = createTheme({
    palette: {
        primary: {
            main: '#06215C',
            backgroundColor: "#F5F5F5",
        },
        secondary: {
            main: '#EB3223',
            text: '#9F9F9F'
        },
        light:{
            white: '#fff',
            black: '#000',
            transparentGray: 'rgba(0,0,0, 0.1)',
            transparent: 'transparent',
        }
    },
    components: {
        MuiLink: {
            defaultProps: {
                component: AppLink,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState }) => {
                    const { variant, color } = ownerState;
                    let styles = {
                        "& .MuiLoadingButton-loadingIndicator":{
                            color: '#EB3223',
                        }
                    }
                    if(variant === 'text' && color === 'secondary'){
                        styles = {
                            ...styles,
                            border: '1px solid #EB3223',
                            padding: '10px 0',
                            maxWidth: '150px',
                            width: '150px'
                        }
                    }

                    if(variant === 'contained' && color === 'secondary'){
                        styles = {
                            ...styles,
                            background: '#EB3223',
                            padding: '10px 0',
                            color: '#fff',
                            maxWidth: '150px',
                            width: '150px'
                        }
                    }

                    if(variant === 'contained' && color === 'primary'){
                        styles = {
                            ...styles,
                            border: '1px solid #06215C',
                            background: 'transparent',
                            boxShadow: 'none',
                            color: '#06215C',
                            padding: '10px 0',
                            maxWidth: '150px',
                            width: '150px',
                            '&:hover': {
                                color: '#fff',
                            }
                        }
                    }

                    if(variant === 'text' && color === 'primary'){
                        styles = {
                            ...styles,
                            background: 'transparent',
                            padding: '10px 0',
                            color: '#fff',
                            border: '1px solid #fff',
                            maxWidth: '150px',
                            width: '150px'
                        }
                    }

                    return styles
                }
            },
        },
        MuiTypography: {
            defaultProps: {
                fontFamily: ['Open Sans'].join(','),
                variantMapping: {
                    h1: 'h2',
                    h2: 'h3',
                    h3: 'h4',
                    h4: 'h5',
                    h5: 'h6',
                    h6: 'span',
                    subtitle1: 'h3',
                    subtitle2: 'h4',
                    body1: 'span',
                    body2: 'span',
                    body3: 'span',
                    body4: 'span',
                    body5: 'span',
                    body6: 'span',
                },
            },
        },
    },
    typography: {
        fontFamily: `"Open Sans", sans-serif`,
        button: {
            textTransform: 'none'
        },
        h1: {
            fontSize: 50,
            fontWeight: 700,
        },
        h2: {
            fontSize: 40,
            fontWeight: 700,
        },
        h3: {
            fontSize: 30,
            fontWeight: 700,
        },
        h4: {
            fontSize: 20,
            fontWeight: 400,
        },
        h5: {
            fontSize: 14,
            fontWeight: 700,
        },
        h6: {
            fontSize: 14,
            fontWeight: 400,
        },
        body1: {
            fontSize: '0.875rem',
            fontWeight: 600,
        },
        body2: {
            fontSize: '0.875rem',
            fontWeight: 400,
        },
        body3: {
            fontSize: '0.875rem',
            fontWeight: 300,
        },
        body4: {
            fontSize: '0.875rem',
            fontWeight: 700,
        },
        body5: {
            fontSize: '0.75rem',
            fontWeight: 600,
        },
        body6: {
            fontSize: '0.75rem',
            fontWeight: 400,
        },
    },
});

export default theme;