import {memo} from "react";
import {useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";

import {Form} from "../../../components";
import {ROLES} from "../../../constants";
import {withLoading} from "../../../HOCs";

function AdminUserForm(props) {

    const { t } = useTranslation();

    const { onCancel, onSubmitForm } = props;

    const loading = useSelector(state => state.item.isLoading === "setItem" || state.item.isLoading === 'updateItem');

    const schema = yup.object().shape({
        name: yup.string().required(t('required')),
        email: yup.string().required(t('required')).email(),
        role: yup.string().required(t('required')),
    });

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name : props?.name ?? "",
            email: props?.email ?? "",
            role: props?.role ?? ROLES.BLOCKED,
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = async (values)=>{
        const response = await onSubmitForm(values);
        if(response) {
            onCancel()
        }
        return response
    }

    return (
        <Form.FormWrapper
            onSubmit={handleSubmit(onSubmit)}
        >
            <Form.FormInput
                id="admin-user-name"
                name="name"
                control={control}
                size="small"
                errors={errors.name}
                placeholder={t('Name')}
            />
            <Form.FormInput
                disabled
                id="admin-user-email"
                name="email"
                control={control}
                size="small"
                errors={errors.email}
                placeholder={t('Email')}
            />
            <Form.FormSelect
                displayEmpty
                id="admin-user-role"
                name="role"
                items={[ROLES.ADMIN, ROLES.USER, ROLES.BLOCKED].map(role=>({label: role, value: role}))}
                control={control}
                size="small"
                errors={errors.role}
                placeholder={t('Role')}
            />
            <Form.FormButtons loading={loading} onCancel={onCancel}/>
        </Form.FormWrapper>
    )
}

export default memo(withLoading(AdminUserForm))