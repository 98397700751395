import {Box} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {Add} from "@mui/icons-material";

import InputSearch from "./InputSearch";
import TranslationTypography from "./translation-components/TranslationTypography";

export default function TableHeader({value, onChange, children, onClick = null, sx={}}){
    const styles = {
        wrapper: {
            display: 'flex',
            columnGap: '1rem',
            paddingY: '2rem',
            ...sx
        }
    };

    return(
        <Box
            component="div"
            sx={styles.wrapper}
        >
            { onClick && (
                <LoadingButton onClick={onClick} variant='contained' color="secondary"
                            startIcon={<Add/>}>
                <TranslationTypography text="addNew" variant='h6'/>
                </LoadingButton>
            )}
            <InputSearch
                value={value}
                onChange={onChange}
            />
            {children}
        </Box>
    )
}