import { useEffect, memo } from 'react';
import {useSelector} from "react-redux";
import { useLocation, useParams, Outlet } from 'react-router-dom';

import useCommon from '../services/commonService'

function WithNavigationPathName() {
  const { changePath } = useCommon()
  const { pathname } = useLocation()
  const params = useParams()
  const currentPath = useSelector(state=>state.common.currentPath);

  const getRoutePath = (currentPathname, currentParams) => {
    if (!Object.keys(currentParams).length) {
      return currentPathname.replace('/', '')
    }

    let path = currentPathname
    Object.entries(currentParams).forEach(([paramName, paramValue]) => {
      if (paramValue) {
        path = path.replace(paramValue, `:${paramName}`)
      }
    })
    return path.replace('/', '')
  }

  useEffect(() => {
    const path = getRoutePath(pathname, params);
    if(path !== currentPath) {
      changePath(path)
    }
  }, [pathname, params])

  return <Outlet />
}

export default memo(WithNavigationPathName);
