import {
  TableCell,
  IconButton
} from '@mui/material';

import MobileMenu from '../MobileMenu';

function AppTableBodyCell({ row = {}, column = {} }) {
  const renderValue = (columnProps) => {
    if (columnProps.getActions) {
      const actions = columnProps.getActions(row)
      return (
        <MobileMenu>
          {actions.map((item) => {
            if (item === null) {
              return null
            }
            const { Icon, iconProps = {}, ...rest } = item
            return (
              <IconButton {...rest}>
                <Icon {...iconProps}/>
              </IconButton>
            )
          })}
        </MobileMenu>
      )
    }
    if (columnProps?.renderCell) {
      return columnProps.renderCell(row)
    }
    return row[column?.field] || ''
  }

  return <TableCell>{renderValue(column)}</TableCell>
}

export default AppTableBodyCell
