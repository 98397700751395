import { useDispatch } from 'react-redux'
import {useTranslation} from "react-i18next";
import {
  setNotification,
  setLastUpdated, setShownNotification,
} from '../redux/slices/notificationSlice'

function useNotifications() {
  const { t } = useTranslation();
  const dispatch = useDispatch()

  const notificationLastUpdated = () => dispatch(setLastUpdated(Date.now()));

  const setNotificationData = ({ message, variant = 'success' }) => {
    if (message) {
      dispatch(setNotification({message: t(message), variant}))
    }
  };

  const notification = ({ message, variant }) => {
    setNotificationData({message, variant});
    notificationLastUpdated();
  };

  const setShown = (id) => !!id && dispatch(setShownNotification({ id }));

  return {
    notification,
    setNotificationData,
    setShown,
    notificationLastUpdated
  }
}

export default useNotifications;
