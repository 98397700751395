import {memo} from "react";
import {Button} from "@mui/material";
import TranslationTypography from "./translation-components/TranslationTypography";

function ButtonTranslation({text, textProps = {}, ...rest}){
    return(
        <Button {...rest}>
            <TranslationTypography text={text} {...textProps} />
        </Button>
    )
}

export default memo(ButtonTranslation)