import {memo} from "react";
import moment from "moment";
import {useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";

import {genderItems, personStatusItems} from "../../../constants";

import {Form} from "../../../components";
import {getComplianceDefaultValues} from "../../../utils/helpers";
import {withLoading} from "../../../HOCs";

function PersonForm(props) {

    const { t } = useTranslation();

    const { onCancel, onSubmitForm } = props;

    const statuses = Object.keys(personStatusItems).map(key=>({label: personStatusItems[key], value: key}))

    const tags = useSelector(state => state.tags.tags);
    const loading = useSelector(state => state.item.isLoading === "setItem" || state.item.isLoading === 'updateItem');

    const schema = yup.object().shape({
        name: yup.string().required(t('required')),
        gender: yup.string().required(t('required')),
        birthDate: yup.date().required(t('required')),
        address: yup.string().required(t('required')),
        coordinates: yup.string(),
        rating: yup.number().required(t('required')),
        status: yup.string(),
        compliance: yup.array(),
    });

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name : props?.name ?? "",
            gender: props?.gender ?? "",
            birthDate: moment(props?.birthDate) ?? "",
            address: props?.address ?? "",
            coordinates: props?.coordinates ?? "",
            rating: props?.rating ?? 0,
            status: props?.status ?? statuses[0].value,
            compliance: getComplianceDefaultValues({tags, compliance: props?.relationship || props?.compliance})
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = async (values)=>{
        const response = await onSubmitForm(values);
        if(response) {
            onCancel()
        }
        return response
    }

    return (
        <Form.FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <Form.FormInput
                id="person-name"
                name="name"
                control={control}
                size="small"
                errors={errors.name}
                placeholder={t('Name')}
            />
            <Form.FormSelect
                displayEmpty
                id="person-gender"
                name="gender"
                items={genderItems.map(item=>({...item, label: t(item.label)}))}
                control={control}
                size="small"
                errors={errors.gender}
                placeholder={t('Please select gender')}
            />
            <Form.FormDatePicker
                id="person-birthDate"
                name="birthDate"
                control={control}
                errors={errors.birthDate}
                placeholder={t('Birth Date')}
            />
            <Form.FormInput
                id="person-address"
                name="address"
                control={control}
                size="small"
                errors={errors.address}
                placeholder={t('Address')}
            />
            <Form.FormInput
                id="person-coordinates"
                name="coordinates"
                control={control}
                size="small"
                errors={errors.coordinates}
                placeholder={t('Coordinates')}
            />
            <Form.FormInput
                id="person-rating"
                type="number"
                name="rating"
                control={control}
                size="small"
                errors={errors.rating}
                placeholder={t('Rating')}
            />
            <Form.FormSelect
                displayEmpty
                id="person-status"
                name="status"
                items={statuses}
                control={control}
                size="small"
                errors={errors.status}
                placeholder={t('Status')}
            />
            <Form.FormSelect
                multiple
                displayEmpty
                id="person-compliance"
                name="compliance"
                items={tags.map(item=>({value: item.id, label: t(item.name)}))}
                control={control}
                size="small"
                errors={errors.compliance}
                placeholder={t('Compliance')}
            />
            <Form.FormButtons loading={loading} onCancel={onCancel}/>
        </Form.FormWrapper>
    )
}

export default memo(withLoading(PersonForm));