import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import {useTableSearch} from "../../hooks";
import useBanksTable from "../../services/banks/banksTableService";
import useTables from "../../services/tables/tablesService";
import useItem from "../../services/item/itemService";
import useModal from "../../hooks/useModal";

import {
    TableWithWrapper,
    TableHeader,
    TranslationTypography,
    PageWrapper,
    ConfirmModal,
    AppModal
} from "../../components";
import BankForm from "../bank/components/BankForm";
import {prepareDataToBackendFormat} from "../../utils/helpers";

export default function CompaniesPage () {

    const navigate = useNavigate();
    const [selectedId, setSelectId] = useState(null);
    const lastUpdated = useSelector(state => state.table.lastUpdated);

    const { deleteItemData, addItemData } = useItem("/banks");
    const [ getBanksData, setBanksTableSettings,, tableLastUpdated ] = useTables("/banks");
    const {searchValue, setSearchValue} = useTableSearch();
    const [open, handleOpen, handleClose] = useModal();
    const [openAdd, handleAddOpen, handleAddClose] = useModal();

    const onClickRow = (e, id) => navigate(`./${id}`);

    const onDelete = async (id) => {
        handleOpen()
        setSelectId(id)
    }

    const onCancelDelete = async () => {
        handleClose()
        setSelectId(null)
    }

    const onSubmitDelete = async () => {
        let response = null;
        if(selectedId) {
            response = await deleteItemData({id: selectedId});
            if (response) {
                tableLastUpdated()
            }
        }
        return response
    }

    useEffect(()=>{
        getBanksData()
    }, [lastUpdated])

    const onSubmitForm = async (values) => {
        const response = await addItemData({ data: prepareDataToBackendFormat(values)});
        if (response) {
            tableLastUpdated()
        }
        return response
    };

    const { banksColumns } = useBanksTable({onDelete});

    return(
        <PageWrapper>
            <TranslationTypography text="Banks" variant='h3'/>
            <TableHeader onClick={handleAddOpen} value={searchValue} onChange={setSearchValue}/>
            <TableWithWrapper
                onClickRow={onClickRow}
                columns={banksColumns}
                onTableSettingsChange={setBanksTableSettings}
                rowHover
            />
            <ConfirmModal
                title="modal.delete.bank"
                open={open}
                onSubmit={onSubmitDelete}
                onClose={onCancelDelete}
            />
            <AppModal handleClose={handleAddClose} open={openAdd} title="modal.new.bank">
                <BankForm onSubmitForm={onSubmitForm} onCancel={handleAddClose}/>
            </AppModal>
        </PageWrapper>
    )
};