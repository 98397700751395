import {memo} from "react";
import {Box} from "@mui/material";

function PageWrapper({children, ...props}){
    const styles = {
        wrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '2rem 10vw 1rem'
        }
    };

    return(
        <Box
            component="div"
            sx={styles.wrapper}
            {...props}
        >
            {children}
        </Box>
    )
}

export default memo(PageWrapper)