import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  TextField
} from '@mui/material';

import WithButton from './WithButton';

export default function InputRange(props) {
  const { onClick, filterTo, filterFrom, tableSettings, handleClose } = props

  const [minValue, setMinValue] = useState(tableSettings[filterFrom] || '')
  const [maxValue, setMaxValue] = useState(tableSettings[filterTo] || '')

  const { t } = useTranslation()

  const submitRangeValue = () => {
    if (maxValue === 0) {
      onClick({ property: filterTo, values: 0 })
    } else {
      onClick({ property: filterTo, values: maxValue })
    }
    if (minValue === 0) {
      onClick({ property: filterFrom, values: 0 })
    } else {
      onClick({ property: filterFrom, values: minValue })
    }
    onClick({ property: filterTo, values: maxValue })
    onClick({ property: filterFrom, values: minValue })
    handleClose()
  }

  const clearRangeFields = () => {
    onClick({ property: filterTo, values: 0 })
    onClick({ property: filterFrom, values: 0 })
    setMinValue('')
    setMaxValue('')
    handleClose()
  }

  return (
    <WithButton
      clearFilterValue={clearRangeFields}
      submitFilterValue={submitRangeValue}
      firstButtonTitle="Ok"
      secondButtonTitle="Clear">
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        <Box>
          <TextField
            id="input-with-icon-textfield"
            label={t('From')}
            variant="standard"
            value={minValue}
            type="number"
            style={{ width: 100 }}
            onChange={(e) => setMinValue(e.target.value)}
          />

          <TextField
            id="input-with-icon-textfield"
            label={t('To')}
            variant="standard"
            value={maxValue}
            type="number"
            style={{ width: 100, marginLeft: 15 }}
            onChange={(e) => setMaxValue(e.target.value)}
          />
        </Box>
      </Box>
    </WithButton>
  )
};
