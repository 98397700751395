import {memo} from "react";
import {useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";

import * as Form from "../form-components";
import {withLoading} from "../../HOCs";

function BankForm(props) {

    const { t } = useTranslation();

    const {onCancel, onSubmitForm, form = null} = props;

    const tags = useSelector(state => state.tags.tags);
    const loading = useSelector(state => state.item.isLoading === "setItem" || state.item.isLoading === 'updateItem');

    const getFieldByForm = (currentForm) =>{
        const fieldItem = {}
        const fieldValue = {}

        if(!currentForm || currentForm === "companies"){
            fieldValue.company = props?.company ?? ""
            fieldItem.company = yup.string().required(t('required'))
        }

        if(currentForm === "persons"){
            fieldValue.person = props?.person ?? ""
            fieldItem.person = yup.string().required(t('required'))
        }

        return {fieldItem, fieldValue}
    }

    const {fieldItem, fieldValue} = getFieldByForm(form);

    const schema = yup.object().shape({
        accountType: yup.string().required(t('required')),
        ...fieldItem
    });

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            accountType: props?.accountType ?? "",
            ...fieldValue
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = async (values)=>{
        const response = await onSubmitForm(values);
        if(response) {
            onCancel()
        }
        return response
    }

    return (
        <Form.FormWrapper
            onSubmit={handleSubmit(onSubmit)}
        >
            {!form && <Form.FormSelect
                displayEmpty
                id="bank-company-bank"
                name="company"
                items={tags.map(item => ({value: item.id, label: t(item.bankName)}))}
                control={control}
                size="small"
                errors={errors.company}
                placeholder={t('Bank')}
            />}
            {form === "companies" && <Form.FormSelect
                displayEmpty
                id="bank-company"
                name="company"
                items={tags.map(item=>({value: item.id, label: t(item.name)}))}
                control={control}
                size="small"
                errors={errors.company}
                placeholder={t('Company')}
            />}
            {form === "persons" && <Form.FormSelect
                displayEmpty
                id="bank-person"
                name="person"
                items={tags.map(item=>({value: item.id, label: t(item.name)}))}
                control={control}
                size="small"
                errors={errors.person}
                placeholder={t('Person')}
            />}
            <Form.FormInput
                id="bank-accountType"
                name="accountType"
                size="small"
                control={control}
                errors={errors.accountType}
                placeholder={t('Account Type')}
            />
            <Form.FormButtons loading={loading} onCancel={onCancel}/>
        </Form.FormWrapper>
    )
}

export default memo(withLoading(BankForm))