
function useTableForms(){
    const getCreateUrl = ({company, person, flag, path = ""}) => {
        let url = null;

        if(path === "/banks"){
            switch (flag) {
                case "companies": {
                    url = `/banks/${person}/companies/${company}`
                    break
                }
                case "persons": {
                    url = `/banks/${company}/persons/${person}`
                    break
                }
                default :{
                    return url
                }
            }

            return url
        }

        switch (flag) {
            case "employee":
            case "companies": {
                url = `/employers/${company}/${person}`
                break
            }
            case "wwe": {
                url = `/owners/${company}/${person}`
                break
            }
            case "bank":
            case "accounts": {
                url = `/banks/${company}${path}/${person}`
                break
            }
            case "compliance": {
                url = `/persons/${person}/compliance/${company}`
                break
            }
            case "family": {
                url = `/persons/${person}/family/${company}`
                break
            }
            case "stock": {
                url = `/shareholders/${person}/companies/${company}`
                break
            }
            case "shareholder-company": {
                url = `/shareholders/${company}/companies/${person}`
                break
            }
            case "shares":
            case "shareholder": {
                url = `/shareholders/${company}/${person}`
                break
            }
            default :{
                return url
            }
        }

        return url
    }

    const getDeleteUrl = ({id, flag, path = null}) => {
        let url = null;

        if(path === "/banks"){
            switch (flag) {
                case "companies": {
                    url = `/banks/${id}/companies`
                    break
                }
                case "persons": {
                    url = `/banks/${id}/persons`
                    break
                }
                default :{
                    return url
                }
            }

            return url
        }

        switch (flag) {
            case "companies":
            case "employee": {
                url = `/employers/${id}`
                break
            }
            case "wwe": {
                url = `/owners/${id}`
                break
            }
            case "bank":
            case "accounts": {
                url = `/banks/${id}/persons`
                break
            }
            case "compliance": {
                url = `persons/compliance/${id}`
                break
            }
            case "family": {
                url = `/families/${id}`
                break
            }
            case "shares":
            case "shareholder": {
                url = `/shareholders/${id}`
                break
            }
            case "stock":
            case "shareholder-company": {
                url = `/shareholders/companies/${id}`
                break
            }
            default :{
                return url
            }
        }

        return url
    }

    const getTagsUrl = (currentForm, path) =>{
        const companyForms = ["companies", "shares", "shareholder-company", "stock"];
        const personForms = ["compliance", "family", "employee", "shareholder", "persons"];
        const bankForms = ["accounts", "bank"];

        switch (path) {
            case "/persons":{
                companyForms.push("wwe")
                break
            }
            case "/companies":{
                personForms.push("wwe")
                break
            }
            default:{
                break
            }
        }

        if(companyForms.includes(currentForm)){
            return '/companies'
        }

        if(personForms.includes(currentForm)){
            return '/persons'
        }

        if(bankForms.includes(currentForm)){
            return '/banks'
        }

        return null
    }
    return({
        getCreateUrl,
        getDeleteUrl,
        getTagsUrl
    })
}

export default useTableForms;