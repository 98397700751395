import {useEffect, memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import AppTable from "./table";
import {resetTableState} from "../redux/slices/tableSlice";

function TableWithWrapper({sx={}, ...props}){

    const styles = {
        tableWrapper: {
            maxHeight: '57vh',
            height: '57vh',
            overflowY: 'auto',
            width: '100%',
            ...sx
        }
    }

    const dispatch = useDispatch();
    const tableData = useSelector(state => state.table.tableData);
    const isLoading = useSelector(state => state.table.isLoading);
    const totalCount = useSelector(state => state.table.totalCount);
    const {page, size} = useSelector(state => state.table.tableSettings);

    const resetTable = () => dispatch(resetTableState());

    useEffect(() => ()=> resetTable(), [])

    return <AppTable
        sx={styles.tableWrapper}
        rows={tableData}
        totalCount={totalCount}
        page={page}
        pageSize={size}
        loading={isLoading}
        rowsPerPageOptions={[10, 50, 100]}
        {...props}/>
}

export default memo(TableWithWrapper);