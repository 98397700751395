import {
  TableCell,
  Box,
  TableSortLabel
} from '@mui/material';

import AppTableFilter from './AppTableFilter';

function AppTableHeadCell(props) {

  const {
    orderBy,
    order,
    createSortHandler,
    createFilterHandler,
    field = null,
    headerName = '',
    sortable = false,
    filterName = null,
    filterFrom = null,
    filterToo = null,
    filterList = [],
    filters,
    flex = 1,
    onRequestFilter,
    tableSettings,
  } = props

  const styles = {
    wrapper: {
      width: '100%',
      display: 'flex',
      textTransform: 'uppercase',
      color: 'secondary.text'
    },
    cell: {
      border: 'none',
      flex
    }
  }

  return (
    <TableCell
      style={styles.cell}
      sortDirection={orderBy === field ? order : false}>
      <Box sx={styles.wrapper}>
        <TableSortLabel
          {...(sortable
            ? {
                active: orderBy === field,
                direction: orderBy === field ? order : 'asc',
                onClick: createSortHandler(field),
              }
            : {
                hideSortIcon: true,
              })}>
          {headerName}
        </TableSortLabel>
        {filterName !== null || filterToo !== null || filterFrom !== null ? (
          <AppTableFilter
            filters={!filters ? [] : filters}
            onClick={createFilterHandler(filterName)}
            filterList={filterList}
            filterFrom={filterFrom}
            filterToo={filterToo}
            onRequestFilter={onRequestFilter}
            filterName={filterName}
            tableSettings={tableSettings}
          />
        ) : null}
      </Box>
    </TableCell>
  )
}

export default AppTableHeadCell
