import {Statistics, Wiki, Wrench} from "../icons";

export const personTabs = [
    {
        label:"Companies",
        value: "companies",
        table: "companies",
    },
    {
        label:"Company shares",
        value: "shares",
        table: "shares",
    },
    {
        label:"WWE",
        value: "wwe",
        table: "wwe",
    },
    {
        label:"Bank accounts",
        value: "accounts",
        table: "accounts",
    },
    {
        label:"Compliance",
        value: "compliance",
        table: "compliance",
    },
    {
        label:"Family members",
        value: "family",
        table: 'family',
    }
];

export const companyTabs = [
    {
        label:"Employees",
        value: "employee",
        table: "employee",
    },
    {
        label:"Shareholders",
        value: "shareholder",
        table: "shareholders",
    },
    {
        label:"Shareholder companies",
        value: "shareholder-company",
        table: "shares",
    },
    {
        label:"Stock companies",
        value: "stock",
        table: "shares",
    },
    {
        label:"WWE",
        value: "wwe",
        table: "wwe",
    },
    {
        label:"Bank accounts",
        value: "bank",
        table: "accounts",
    },
];

export const bankTabs = [
    {
        label:"Person accounts",
        value: "persons",
        table: "person",
    },
    {
        label:"Company accounts",
        value: "companies",
        table: "company",
    },
];

export const userAdminTab = {
        label: "Users",
        value: "users",
}

export const requestsAdminTab = {
    label: "Registration requests",
    value: "requests"
}

export const settingsTabs = [
    {
        label:"Settings",
        value: "settings",
    },
    {
        label:"Edit your profile",
        value: "editProfile",
    },
    {
        label:"Change password",
        value: "changePassword",
    },
]

export const headerIconTabs = [
    {
        label: "Wiki",
        value: "wiki",
        icon: <Wiki/>
    },
    {
        label:"Statistics",
        value: "statistics",
        icon: <Statistics/>
    },
    {
        label:"Admin",
        value: "admin",
        icon: <Wrench/>
    }
]

export const headerTabs = [
    {
        label: "Persons",
        value: "persons",
    },
    {
        label: "Companies",
        value: "companies",
    },
    {
        label: "Banks",
        value: "banks",
    },
    {
        label:"About",
        value: "about",
    },
];

