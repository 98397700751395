import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import {Link} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Form, TransTypography } from '../../../components'

import {PAGES} from "../../../constants";

export default function LoginForm(props) {

  const { t } = useTranslation();

  const {onSubmitForm, loading = false} = props;

  const schema = yup.object().shape({
      email: yup.string().required(t('required')).email(),
      password: yup.string().required(t('required')),
  });

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: { email: props?.email ?? "", password: props?.password ?? "" },
    resolver: yupResolver(schema),
  });

  const styles = {
      text:{
          color: 'light.white',
      },
      link:{
          color: 'secondary.main'
      },
      button: {
          marginBottom: '2rem',
          marginTop: '1rem'
      }
  };

  return (
    <Form.FormWrapper onSubmit={handleSubmit(onSubmitForm)}>
        <Form.FormInput
            id="login-email"
            name="email"
            control={control}
            size="small"
            errors={errors.email}
            placeholder={t('Email')}
        />
        <Form.FormInputPassword
            id="login-password"
            name="password"
            control={control}
            size="small"
            errors={errors.password}
            placeholder={t('Password')}
        />
        <LoadingButton
            loading={loading}
            type='submit'
            variant="contained"
            color="secondary"
            sx={styles.button}
        >
            {t('Login')}
        </LoadingButton>
        <TransTypography i18nKey="login.loginLostPassword" variant="body2" sx={styles.text}>
            Lost your password? <Link
            href={`/${PAGES.RESET_PASSWORD}`}
            variant="body2"
            underline="none"
            color="inherit"
            sx={styles.link}
            >Click here</Link>
        </TransTypography>
    </Form.FormWrapper>
  )
};
