import {useState} from "react";
import { useController } from 'react-hook-form';

import {FormHelperText, FormControl, TextField} from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";

export default function FormDatePicker({
  id = null,
  label = '',
  name,
  control,
  errors = '', sx={},
  ...rest
}) {

  const {
    field: { ref, onChange, onBlur, value, ...controllerProps },
  } = useController({
    name,
    control,
    defaultValue: '',
  });
    const [date, setDate] = useState(value? moment(value): "")
  const props = { ...controllerProps, ...rest };

  const styles = {
      formControl:{
          marginBottom: '25px'
      },
      error:{
          position: 'absolute',
          top: '50px',
          width: '300px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
      },
      input: {
          bgcolor: 'light.white',
          "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                  border: "none"
              }
          },
          ...sx
      }
  };

  const handleChange = (newDate) => {
      setDate(newDate)
      onChange(newDate?._d || newDate)
  }

  return (
    <FormControl fullWidth sx={styles.formControl} variant="outlined">
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
                views={['day', 'month', 'year']}
                inputFormat="DD/MM/YYYY"
                label={label}
                id={id || `date-picker-${name}`}
                value={date}
                inputRef={ref}
                onBlur={onBlur}
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} sx={styles.input} error={!!errors} />}
                maxDate={Date.now()}
                sx={styles.input}
                {...props}
            />
        </LocalizationProvider>
      {errors && (
        <FormHelperText sx={styles.error} error id={`date-picker-error-${name}`}>
          {errors?.message}
        </FormHelperText>
      )}
    </FormControl>
  )
};
