import {memo, useEffect} from "react";
import {useSelector} from "react-redux";

import useItem from "../../services/item/itemService";
import useTables from "../../services/tables/tablesService";
import useTags from "../../services/tags/tagsService";
import {useTableForms} from "../../hooks";
import {prepareDataToBackendFormat} from "../../utils/helpers";

import CompanyForm from "./CompanyForm";
import CompanyShareForm from "./CompanyShareForm";
import WweForm from "./WweForm";
import BankForm from "./BankForm";
import ComplianceForm from "./ComplianceForm";

function TableForms({id, form, onCancel, path = ""}) {

    const isLoading = useSelector(state => state.tags.isLoading);

    const { addItemData } = useItem();
    const [,,,tableLastUpdated] = useTables();
    const [getTagsData, resetTagsState] = useTags();
    const {getCreateUrl, getTagsUrl} = useTableForms();

    const onAddSubmit = async ({url, data}) => {
        const response = await addItemData({url, data})
        if(response){
            tableLastUpdated()
        }
        return response
    }

    const onSubmitForm = (values) => {
        const {company = id, person = id, ...data} = values
        const url = getCreateUrl({company, person, flag: form, path});
        return url && onAddSubmit({url, data: prepareDataToBackendFormat(data)})
    };

    useEffect(()=>{
        const url = getTagsUrl(form, path);
        if(form && url){
            getTagsData(url)
        }

    }, [form])

    useEffect(()=>()=> resetTagsState(), []);

    return(
        <>
            {path !== "/banks" && (form === "companies" || form === "employee") && (
                <CompanyForm
                    isLoading={isLoading}
                    form={form}
                    onSubmitForm={onSubmitForm}
                    onCancel={onCancel}
                />
            )}
            {
                ["shares", "shareholder-company", "stock", "shareholder"].includes(form) && (
                    <CompanyShareForm
                        isLoading={isLoading}
                        form={form}
                        onSubmitForm={onSubmitForm}
                        onCancel={onCancel}
                    />
                )}
            {form === "wwe" && (
                <WweForm
                    isLoading={isLoading}
                    path={path}
                    onSubmitForm={onSubmitForm}
                    onCancel={onCancel}
                />
            )}
            {(form === "accounts" || form === "bank") && (
                <BankForm
                    isLoading={isLoading}
                    onSubmitForm={onSubmitForm}
                    onCancel={onCancel}
                />
            )}
            {path === "/banks" && (form === "companies" || form === "persons") && (
                <BankForm
                    isLoading={isLoading}
                    form={form}
                    onSubmitForm={onSubmitForm}
                    onCancel={onCancel}
                />
            )}
            {(form === "compliance" || form === "family") && (
                <ComplianceForm
                    isLoading={isLoading}
                    onSubmitForm={onSubmitForm}
                    onCancel={onCancel}
                />
            )}
        </>
    )
}

export default memo(TableForms)