import { useTranslation } from 'react-i18next';

import {Close, Done} from "@mui/icons-material";
import useTableCellRender from "../useTableCellRender";

const useAdminsRequestsTable = ({onApprove, onReject }) => {
  const { t } = useTranslation();

  const {renderDate} = useTableCellRender();

  const getColumns = () => [
    {
      field: 'registrationTime',
      flex: 2,
      minWidth: 100,
      headerName: t('Registration time'),
      renderCell: (row) => renderDate(row?.registrationTime, "DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: 'name',
      flex: 2,
      minWidth: 200,
      headerName: t('Name'),
    },
    {
      field: 'email',
      flex: 2,
      minWidth: 200,
      headerName: t('Email'),
    },
    {
      field: 'id',
      flex: 1,
      minWidth: 100,
      headerName: t('Actions'),
      getActions: (params) => [
        {
          sx: { color: 'light.black' },
          key: `Edit${params.id}`,
          Icon: Done,
          iconProps:{
            width: '0.7em',
            height: '0.7em',
          },
          onClick: (e) => {
            e.stopPropagation()
            if (onApprove) {
              onApprove(params.id)
            }
          }
        },
        {
          sx: { color: 'light.black' },
          key: `Delete${params.id}`,
          Icon: Close,
          iconProps:{
            width: '0.7em',
            height: '0.7em',
          },
          onClick: (e) => {
            e.stopPropagation()
            if (onReject) {
              onReject(params.id)
            }
          }
        },
      ],
    },
  ]
  return { adminsRequestsColumns: getColumns() }
}

export default useAdminsRequestsTable
