import {memo} from "react";
import {useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";

import * as Form from "../form-components";
import {withLoading} from "../../HOCs";

function ComplianceForm(props) {

    const { t } = useTranslation();

    const {onCancel, onSubmitForm} = props;

    const tags = useSelector(state => state.tags.tags);
    const loading = useSelector(state => state.item.isLoading === "setItem" || state.item.isLoading === 'updateItem');

    const schema = yup.object().shape({
        person: yup.string().required(t('required')),
        relationship: yup.string().required(t('required')),
    });

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            person : props?.person ?? "",
            relationship: props?.relationship ?? "",
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = async (values)=>{
        const response = await onSubmitForm(values);
        if(response) {
            onCancel()
        }
        return response
    }

    return (
        <Form.FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <Form.FormSelect
                displayEmpty
                id="compliance-person"
                name="person"
                items={tags.map(item=>({value: item.id, label: t(item.name)}))}
                control={control}
                size="small"
                errors={errors.person}
                placeholder={t('Person')}
            />
            <Form.FormInput
                id="compliance-relationship"
                name="relationship"
                control={control}
                size="small"
                errors={errors.relationship}
                placeholder={t('Relationship')}
            />
            <Form.FormButtons loading={loading} onCancel={onCancel}/>
        </Form.FormWrapper>
    )
}

export default memo(withLoading(ComplianceForm))