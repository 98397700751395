import {memo} from "react";

import { Box, Typography } from "@mui/material";

import TranslationTypography from "./translation-components/TranslationTypography";

function RowDataItem({field, text, sx={}, textSx = {}}){
    const styles = {
        wrapper:{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            alignItems: 'center',
            wordBreak: 'break-all',
            columnGap: '1rem',
            ...sx
        }
    }
    return(
        <Box
            component='div'
            sx={styles.wrapper}
        >
            <TranslationTypography text={field} postfix=":" variant='h5'/>
            <Typography sx={textSx} variant='h6'>
                {text}
            </Typography>
        </Box>
    )
}

export default memo(RowDataItem)