import { createAsyncThunk } from '@reduxjs/toolkit';
import API from "../../HOCs/InterceptorsProvider";
import {isResponseOk} from "../../utils/helpers";

const getTableData = createAsyncThunk(
  'GET_TABLE_DATA',
  async ({url, params = {} }, { getState }) => {
      const state = getState();
    try {
      const response = await API.get(url, { params: Object.keys(params).length > 0 ? params : state.table.tableSettings });
      if (isResponseOk(response) && Array.isArray(response.data?.content)) {
          return {data: response.data?.content, totalCount: response.data?.totalElements}
      }

    } catch (e) {
      console.log(e)
    }
      return {
          tableData: state.table.tableData,
          totalCount: state.table.totalCount,
      }
  },
);

export {getTableData};
