import {
    Button,
    Box
} from '@mui/material';

import TranslationTypography from "./translation-components/TranslationTypography";

export default function WithButton({
  children,
  submitFilterValue,
  clearFilterValue,
  firstButtonTitle,
  secondButtonTitle,
}) {
  return (
    <Box>
      <Box>{children}</Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          position: 'sticky',
          bottom: 0,
          paddingBottom: 0,
          paddingLeft: '10px',
          paddingRight: '20px',
          bgcolor: '#FFF',
          height: 40,
          zIndex: 99,
          borderTop: '1px solid #E0E0E0',
        }}>
        <Button onClick={submitFilterValue} color="primary" size="small">
            <TranslationTypography text={firstButtonTitle}/>
        </Button>
        <Button onClick={clearFilterValue} color="secondary" size="small">
            <TranslationTypography text={secondButtonTitle}/>
        </Button>
      </Box>
    </Box>
  )
};
