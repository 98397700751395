import {memo, useEffect, useState} from "react";
import {useSelector} from "react-redux";

import useTables from "../services/tables/tablesService";
import useTabTable from "../services/tab-services/tabTableService";

import TabPanel from "./TabPanel";
import TableWithWrapper from "./AppTableWrapper";
import {useModal, useTableForms} from "../hooks";
import ConfirmModal from "./ConfirmModal";
import useItem from "../services/item/itemService";

function TabTable ({value, tab, table, url="/", path="/persons", isDemo= false, sx= {}}){
    const [selectedId, setSelectId] = useState(null);
    const [openDelete, handleOpen, handleClose] = useModal();
    const lastUpdated = useSelector(state => state.table.lastUpdated);
    const {getDeleteUrl} = useTableForms();
    const { deleteItemData } = useItem();
    const [getData, setTableSettings, ,tableLastUpdated] = useTables(`${url}/${value}`);

    useEffect(()=>{
        getData()
    }, [lastUpdated])

    const onDelete = async (id) => {
        handleOpen()
        setSelectId(id)
    }

    const onCancelDelete = async () => {
        handleClose()
        setSelectId(null)
    }

    const onSubmitDelete = async () => {
        let response = null;
        if(selectedId) {
            response = await deleteItemData({url: getDeleteUrl({id: selectedId, flag: value, path})})
            if(response) {
                tableLastUpdated()
            }
        }
        return response
    }

    const tabRows = useTabTable({onDelete, path, isDemo});

    return(
        <TabPanel
            key={`${value}-TabPanel`}
            value={tab} index={value}
        >
            <TableWithWrapper
                sx={sx}
                columns={tabRows[table]}
                onTableSettingsChange={setTableSettings}
            />
            <ConfirmModal
                title="Delete"
                open={openDelete}
                onSubmit={onSubmitDelete}
                onClose={onCancelDelete}
            />
        </TabPanel>
    )
}

export default memo(TabTable)