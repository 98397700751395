import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useDebounce from "./useDebounce";
import {setLastUpdated, setTableSettings} from "../redux/slices/tableSlice";

function useTableSearch() {
    const dispatch = useDispatch();
    const search = useSelector(state => state.table.tableSettings.search);
    const [searchValue, setSearchValue] = useState('');
    const debouncedValue = useDebounce(searchValue, 500);

    const setTableSearch = () => {
        dispatch(setTableSettings({search: debouncedValue, page: 0}));
        dispatch(setLastUpdated(Date.now()));
    };

    useEffect(()=>{
        if(debouncedValue !== search) {
            setTableSearch()
        }
    }, [debouncedValue])

    return {searchValue, setSearchValue}
}

export default useTableSearch