import {memo} from "react";

import {Box} from "@mui/material";

function TabPanel({ children, value, index, ...rest }) {
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...rest}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </Box>
    );
}

export default memo(TabPanel)