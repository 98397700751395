import {useEffect} from "react";
import {useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";

import Box from '@mui/material/Box';

import { Trace } from '../../icons';

import useAuth from "../../services/authService";

import { TranslationTypography, TransTypography } from '../../components';
import RegisterForm from "./components/RegisterForm";
import LoginForm from "./components/LoginForm";

export default function LoginPage () {
    const navigate = useNavigate();

    const isAuth = useSelector(state=>state.auth.isAuth);
    const isLoading = useSelector(state=>state.auth.isLoading);
    const { login, register } = useAuth();

    const onSubmitLogin = async ({email, password}) => {
        await login({email, password})
    }

    const onSubmitRegister = async ({name, email, password}) => register({name, email, password});

    useEffect(()=>{
        if(isAuth){
            navigate("/");
        }
    }, [isAuth])

    const styles = {
        wrapper: {
            width: '100%',
            maxWidth: '1200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        headerWrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '2rem'
        },
        title: {
            margin: '2rem 0'
        },
        text:{
            maxWidth: '55%',
            lineHeight: '1.5rem'
        },
        body:{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '2.5rem'
        },
        bodyRegister: {
            padding: '1.5rem 4rem',
            bgcolor: 'light.white'
        },
        bodyTitle: {
            marginBottom: '2rem'
        },
        bodyLogin: {
            padding: '1.5rem 4rem',
            bgcolor: 'primary.main',
            color: 'light.white'
        }
    };

  return (
        <Box
            component="div"
            sx={styles.wrapper}
        >
            <Box
                component="div"
                sx={styles.headerWrapper}
            >
                <Trace width={202} height={31} />
                <TranslationTypography variant="h1" sx={styles.title} text="login.mainTitle"/>
                <TransTypography variant="body2" align="center" sx={styles.text}>
                    login.mainText
                </TransTypography>
            </Box>
            <Box
                component="div"
                sx={styles.body}
            >
                <Box
                    component="div"
                    sx={styles.bodyRegister}
                >
                    <TransTypography variant="h3" sx={styles.bodyTitle}>
                        login.registerTitle
                    </TransTypography>
                    <RegisterForm loading={isLoading === "register"} onSubmitForm={onSubmitRegister}/>
                </Box>
                <Box
                    component="div"
                    sx={styles.bodyLogin}
                >
                    <TransTypography variant="h3" sx={styles.bodyTitle}>
                        login.haveAccount
                    </TransTypography>
                    <LoginForm loading={isLoading === "authLogin"} onSubmitForm={onSubmitLogin}/>
                </Box>
            </Box>
        </Box>
    );
};
