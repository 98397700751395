import { useEffect, useState } from 'react'

function useDebounce(value = "", delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value || "")

  useEffect(() => {
    const handler = setTimeout(() => {
      if (value !== debouncedValue) {
        setDebouncedValue(value)
      }
    }, delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return debouncedValue
}

export default useDebounce
