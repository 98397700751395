import * as yup from "yup";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";
import {Box} from '@mui/material';
import {LoadingButton} from "@mui/lab";

import useAuth from "../../services/authService";
import {Form, TranslationTypography} from '../../components';

export default function ResetPassword () {
    const styles = {
        wrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        title:{
           margin: '1rem 0'
        },
        form:{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '1200px',
            minWidth: '350px',
            padding: '3rem 0'
        },
        input: {
            bgcolor: 'light.white',
        },
        button: {
            marginBottom: '2rem',
            marginTop: '1rem'
        }
    };

    const { t } = useTranslation();

    const isLoading = useSelector(state => state.auth.isLoading === "resetPassword");

    const { resetPassword } = useAuth();

    const schema = yup.object().shape({
        email: yup.string().required(t('required')).email(),
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: { email: "" },
        resolver: yupResolver(schema),
    });

    const onSubmit = async (values) => {
        const response = await resetPassword(values)
        if(response){
            reset();
        }
    };

  return (
        <Box
            component="div"
            sx={styles.wrapper}
        >
            <TranslationTypography sx={styles.title} variant="h2" text="Forgot password"/>
            <Form.FormWrapper
                onSubmit={handleSubmit(onSubmit)}
                sx={styles.form}
            >
                <Form.FormInput
                    id="reset-password-email"
                    name="email"
                    control={control}
                    size="small"
                    errors={errors.email}
                    placeholder={t('Email')}
                    sx={styles.input}
                />
                <LoadingButton
                    loading={isLoading}
                    type='submit'
                    variant="contained"
                    color="secondary"
                    sx={styles.button}
                >
                    {t('Submit')}
                </LoadingButton>
            </Form.FormWrapper>
        </Box>
    );
};
