import { useTranslation } from 'react-i18next';
import {Trash} from "../../icons";
import useTableCellRender from "../useTableCellRender";
import {statusItems} from "../../constants";

const useTabTable = ({ onDelete = null, path, isDemo }) => {
  const { t } = useTranslation();

  const {renderDate} = useTableCellRender();

  const fields = {
    companyName: {
      field: 'name',
      minWidth: 200,
      headerName: t('Company name'),
    },
    bankName: {
      field: 'name',
      minWidth: 200,
      headerName: t('Bank name'),
    },
    function: {
      field: 'function',
      minWidth: 100,
      headerName: t('Function'),
    },
    sinceDate: {
      field: 'sinceDate',
      minWidth: 100,
      headerName: t('Since'),
      renderCell: (row) => renderDate(row?.sinceDate),
    },
    address: {
      field: 'address',
      minWidth: 200,
      headerName: t('Address'),
    },
    leftDate: {
      field: 'leftDate',
      minWidth: 100,
      headerName: t('Left'),
      renderCell: (row) => renderDate(row?.leftDate),
    },
    status: {
      field: 'status',
      minWidth: 100,
      headerName: t('Status'),
      renderCell: (row) => statusItems[row?.status] ?? "",
    },
    share: {
      field: 'share',
      minWidth: 100,
      headerName: `${t('Share')} %`,
      renderCell: (row) => row?.share || 0,
    },
    accountType: {
      field: 'accountType',
      minWidth: 100,
      headerName: t('Account type'),
    },
    personName: {
      field: 'name',
      minWidth: 100,
      headerName: t('Person Name'),
    },
    relationship: {
      field: 'relationship',
      minWidth: 100,
      headerName: t('Relationship'),
    },
    actions: {
      field: 'id',
      flex: 1,
      minWidth: 100,
      headerName: t('Actions'),
      getActions: (params) => [
        {
          sx: { color: 'light.black' },
          key: `Delete${params.id}`,
          Icon: Trash,
          iconProps:{
            width: '0.7em',
            height: '0.7em',
          },
          onClick: (e) => {
            e.stopPropagation()
            if (onDelete) {
              onDelete(params.id)
            }
          }
        },
      ],
    },
  }

  const getColumns = () => ({
    companies: [fields.companyName, fields.address, fields.function, fields.sinceDate, fields.leftDate, fields.status],
    employee: [fields.personName, fields.address, fields.function, fields.sinceDate, fields.leftDate, fields.status],
    shares: [fields.companyName, fields.address, fields.share, fields.sinceDate, fields.status],
    shareholders: [fields.personName, fields.address, fields.share, fields.sinceDate, fields.status],
    wwe: [path === '/persons' ? fields.companyName : fields.personName, fields.address, fields.sinceDate, fields.status],
    accounts: [fields.bankName, fields.address, fields.accountType],
    compliance: [fields.personName, fields.relationship],
    family: [fields.personName, fields.relationship],
    company: [fields.companyName, fields.address, fields.accountType],
    person: [fields.personName, fields.address, fields.accountType],
  })
  const columns = getColumns();
  if(!isDemo){
    Object.keys(columns).forEach(key=>columns[key].push(fields.actions))
  }
  return { ...columns }
}

export default useTabTable;
