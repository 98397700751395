import { useDispatch } from 'react-redux';

import {
  setLanguage,
  setPath,
} from '../redux/slices/commonSlice';

function useCommon() {
  const dispatch = useDispatch();

  const changeLanguage = (value) => dispatch(setLanguage(value));
  const changePath = (value) => dispatch(setPath(value));

  return {
    changeLanguage,
    changePath,
  };
}

export default useCommon;
