export const demoPerson = {
    name: 'Thomas Havranek',
    rating: 600,
    gender: 'Male',
    birthDate: '27.08.1969',
    status: 'active',
    coordinates: 'adasdas',
    compliance: ['SIP', 'PEP'],
    address: 'Felbergasse 132 Wien'
}

export const companyData = {
    companyName: 'Cin Consult Unternehmensberatungs Gmbh',
    address: 'Beatrixgasse 32 Wien',
    creationDate: '22/12/1981',
    since: '05/06/2014',
    rating: 200,
    selfshare: 11,
    status: 'Active',
}

export const bankData = {
    bankName: 'test bank',
    address: 'Beatrixgasse 32 Wien',
}

export const demoCompany = {
    companyName: 'Cin Consult Unternehmensberatungs Gmbh',
    address: 'Beatrixgasse 32 Wien',
    function: 'Geschäftsführer',
    since: '05/06/2014',
    left: '',
    status: 'Active',
}

export const demoCompanyShares = {
    companyName: 'Cin Consult Unternehmensberatungs Gmbh',
    address: 'Beatrixgasse 32 Wien',
    share: 50,
    since: '05/06/2014',
    status: 'Active',
}

export const demoCompanyBank = {
    companyName: 'Cin Consult Unternehmensberatungs Gmbh',
    address: 'Beatrixgasse 32 Wien',
    accountType: 'asdas'
}

export const demoCompanyCompliance = {
    personName: 'Thomas Havranek',
    relationship: 'test relationship',
}

export const demoActionsLog = {
    dateTime: '2022-12-16 09:56:12',
    userName: 'test',
    email: 'admin.admin@gmail.com',
    itemName: '123',
    itemId: '38561d60-e45e-4303-bd83-ff6830a32f37',
}

export const demoAdminsUsers = {
    name: 'Cin Consult Unternehmensberatungs Gmbh',
    email: 'admin.admin@gmail.com',
    role: 'admin',
}

export const demoAdminsUsersRegistration = {
    registrationTime: '2022-12-16 09:56:12',
    userName: 'test',
    email: 'admin.admin@gmail.com',
}

function getData (count, item) {
    const data = []
    for (let i = 0; i< count; i += 1){
        data.push({id: i, ...item})
    }

    return data
}

export const demoCompanyTableData = getData(10, demoCompany);
export const companyTableData = getData(10, companyData);
export const bankTableData = getData(5, bankData);
export const demoCompanySharesTableData = getData(10, demoCompanyShares);
export const demoCompanyBankTableData = getData(10, demoCompanyBank);
export const demoCompanyComplianceTableData = getData(10, demoCompanyCompliance);
export const demoPersonTableData = getData(10, demoPerson);
export const demoActionsLogData = getData(10, demoActionsLog);
export const demoAdminsUsersData = getData(10, demoAdminsUsers);
export const demoAdminsUsersRegistrationData = getData(5, demoAdminsUsersRegistration);