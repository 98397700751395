import {memo} from "react";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {useTranslation} from "react-i18next";

import {LoadingButton} from "@mui/lab";

import {Form} from "../../components";

function ResetPasswordForm(props) {

    const styles = {
        form:{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '1200px',
            minWidth: '350px',
            padding: '3rem 0'
        },
        input: {
            bgcolor: 'light.white',
        },
        button: {
            marginBottom: '2rem',
            marginTop: '1rem'
        }
    };

    const { t } = useTranslation();

    const {onSubmitForm, loading = false, token = null} = props;

    const schema = yup.object().shape({
        newPassword: yup.string().required(t('required')),
        confirmPassword: yup.string().oneOf([yup.ref('newPassword'), null], t("matchPasswords")),
        ...(!token?{ oldPassword: yup.string().required(t('required'))}:{})
    });

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: {
            newPassword: "",
            confirmPassword: "",
            ...(!token?{ oldPassword: "" }:{})
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = async (values)=>{
        const response = await onSubmitForm(values);
        if(response) {
            reset()
        }
        return response
    }

    return(
        <Form.FormWrapper
            onSubmit={handleSubmit(onSubmit)}
            sx={styles.form}
        >
            {!token && <Form.FormInputPassword
                id="reset-password-oldPassword"
                name="oldPassword"
                control={control}
                size="small"
                errors={errors.oldPassword}
                placeholder={t('Old Password')}
                sx={styles.input}
            />}
            <Form.FormInputPassword
                id="reset-password-newPassword"
                name="newPassword"
                control={control}
                size="small"
                errors={errors.newPassword}
                placeholder={t('New Password')}
                sx={styles.input}
            />
            <Form.FormInputPassword
                id="reset-password-confirmPassword"
                name="confirmPassword"
                control={control}
                size="small"
                errors={errors.confirmPassword}
                placeholder={t('Confirm Password')}
                sx={styles.input}
            />
            <LoadingButton
                loading={loading}
                type='submit'
                variant="contained"
                color="secondary"
                sx={styles.button}
            >
                {t(token?'Submit':'Save')}
            </LoadingButton>
        </Form.FormWrapper>
    )
}

export default memo(ResetPasswordForm)