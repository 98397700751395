import { useDispatch } from 'react-redux';
import {
  authChangePassword,
  authLogin,
  authLogout,
  authRegister,
  authResetPassword,
  authResetPasswordConfirm
} from '../redux/asyncServices/authService';
import {setLoading} from "../redux/slices/authSlice";
import useNotifications from "./notificationService";

function useAuth() {
  const dispatch = useDispatch();

  const { setNotificationData, notificationLastUpdated, notification } = useNotifications();

  const authLoading = (isLoading) => dispatch(setLoading(isLoading));

  const login = ({email, password}) => dispatch(authLogin({email, password}));

  const resetPassword = async ({email}) => {
    authLoading('resetPassword');
    const response = await authResetPassword({email})
    authLoading(null);
    if(response){
      notification({message:"resetPassword.sentMessage"});
    }

    return response
  };

  const resetPasswordConfirm = async (data) => {
    authLoading('resetPasswordConfirm');
    const response = authResetPasswordConfirm(data);
    authLoading(null);
    return response
  }
  const changePassword = (data) => {
    authLoading('changePassword');
    const response = authChangePassword(data);
    authLoading(null);
    return response
  }

  const register = async ({name, email, password}) => {
    authLoading('register');
    const response = await authRegister({name, email, password});
    authLoading(null);

    if(response){
      setNotificationData({message: "login.registerSuccessful"})
    }

    if(response?.message && typeof response?.message === 'string') {
      setNotificationData({message: response?.message, variant: 'warning'})
    }

    notificationLastUpdated();

    return response
  };

  const logout = () => dispatch(authLogout());

  return {
    login,
    register,
    logout,
    resetPassword,
    resetPasswordConfirm,
    changePassword
  };
}

export default useAuth;
