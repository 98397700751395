import { createSlice } from '@reduxjs/toolkit'
import { getLanguage } from '../../utils/helpers'
import {requestCount} from "../asyncServices/commonService";

const initialState = {
  language: getLanguage(),
  currentPath: '',
  requestCount: 0,
  isLoading: false,
}

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload
    },
    setPath: (state, action) => {
      state.currentPath = action.payload
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestCount.pending, (state) => {
      state.isLoading = 'requestCount'
    })
    builder.addCase(requestCount.fulfilled, (state, action) => {
      state.requestCount = action.payload
      state.isLoading = null
    })
  },
})

export const { setLanguage, setLoading, setPath } =
  commonSlice.actions
export default commonSlice.reducer
