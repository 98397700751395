import {memo} from "react";
import {useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";

import {withLoading} from "../../../HOCs";
import {Form} from "../../../components";
import {getComplianceDefaultValues} from "../../../utils/helpers";
import {statusItems} from "../../../constants";

function CompanyForm(props) {

    const { t } = useTranslation();

    const {onCancel, onSubmitForm} = props;

    const statuses = Object.keys(statusItems).map(key=>({label: statusItems[key], value: key}))

    const tags = useSelector(state => state.tags.tags);
    const loading = useSelector(state => state.item.isLoading === "setItem" || state.item.isLoading === 'updateItem');

    const schema = yup.object().shape({
        name: yup.string().required(t('required')),
        creationDate: yup.date().required(t('required')),
        address: yup.string().required(t('required')),
        coordinates: yup.string(),
        rating: yup.number().required(t('required')),
        selfShare: yup.number(),
        status: yup.string(),
        compliance: yup.array(),
    });

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name : props?.name ?? "",
            creationDate: props?.creationDate ?? "",
            selfShare: props?.selfShare ?? 0,
            address: props?.address ?? "",
            coordinates: props?.coordinates ?? "",
            rating: props?.rating ?? 0,
            status: props?.status ?? statuses[0].value,
            compliance: getComplianceDefaultValues({tags, compliance: props?.compliance})},
        resolver: yupResolver(schema),
    });

    const onSubmit = async (values)=>{
        const response = await onSubmitForm(values);
        if(response) {
            onCancel()
        }
        return response
    }

    return (
        <Form.FormWrapper onSubmit={handleSubmit(onSubmit)}>
            <Form.FormInput
                id="company-name"
                name="name"
                control={control}
                size="small"
                errors={errors.name}
                placeholder={t('Name')}
            />
            <Form.FormDatePicker
                id="company-creationDate"
                name="creationDate"
                control={control}
                errors={errors.creationDate}
                placeholder={t('Creation Date')}
            />
            <Form.FormInput
                id="company-address"
                name="address"
                control={control}
                size="small"
                errors={errors.address}
                placeholder={t('Address')}
            />
            <Form.FormInput
                id="company-coordinates"
                name="coordinates"
                control={control}
                size="small"
                errors={errors.coordinates}
                placeholder={t('Coordinates')}
            />
            <Form.FormInput
                id="company-rating"
                type="number"
                name="rating"
                control={control}
                size="small"
                errors={errors.rating}
                placeholder={t('Rating')}
            />
            <Form.FormInput
                id="company-selfShare"
                name="selfShare"
                type="number"
                control={control}
                size="small"
                errors={errors.coordinates}
                placeholder={t('Selfshare (%)')}
            />
            <Form.FormSelect
                displayEmpty
                id="company-status"
                name="status"
                items={statuses}
                control={control}
                size="small"
                errors={errors.status}
                placeholder={t('Status')}
            />
            <Form.FormSelect
                multiple
                displayEmpty
                id="company-compliance"
                name="compliance"
                items={tags.map(item=>({value: item.id, label: t(item.name)}))}
                control={control}
                size="small"
                errors={errors.compliance}
                placeholder={t('Compliance')}
            />
            <Form.FormButtons loading={loading} onCancel={onCancel}/>
        </Form.FormWrapper>
    )
}

export default memo(withLoading(CompanyForm))