import { useTranslation } from 'react-i18next';

import {Trash} from "../../icons";
import useTableCellRender from "../useTableCellRender";
import {statusItems} from "../../constants";

const useCompaniesTable = ({ onDelete }) => {
  const { t } = useTranslation();

  const {renderDate} = useTableCellRender();

  const getColumns = () => [
    {
      field: 'name',
      flex: 2,
      minWidth: 200,
      headerName: t('Company Name'),
    },
    {
      field: 'creationDate',
      flex: 2,
      minWidth: 100,
      headerName: t('Creation date'),
      renderCell: (row) => renderDate(row?.creationDate),
    },
    {
      field: 'address',
      flex: 2,
      minWidth: 200,
      headerName: t('Address'),
    },
    {
      field: 'rating',
      flex: 2,
      minWidth: 100,
      headerName: t('Rating'),
    },
    {
      field: 'selfshare',
      flex: 2,
      minWidth: 100,
      headerName: `${t('Self share')} %`,
    },
    {
      field: 'status',
      flex: 2,
      minWidth: 100,
      headerName: t('Status'),
      renderCell: (row) => statusItems[row?.status] ?? "",
    },
    {
      field: 'id',
      flex: 1,
      minWidth: 100,
      headerName: t('Actions'),
      getActions: (params) => [
        {
          sx: { color: 'light.black' },
          key: `Delete${params.id}`,
          Icon: Trash,
          iconProps:{
            width: '0.7em',
            height: '0.7em',
          },
          onClick: (e) => {
            e.stopPropagation()
            if (onDelete) {
              onDelete(params.id)
            }
          }
        },
      ],
    },
  ]
  return { companiesColumns: getColumns() }
}

export default useCompaniesTable
