import {useDispatch} from 'react-redux';
import {requestCount} from "../../redux/asyncServices/commonService";

function useRequests() {
  const dispatch = useDispatch();

  const getCount = () => {
    dispatch(requestCount({params:{page: 0, size: 1}}))
  };

  return {
    getCount
  }
}

export default useRequests;
