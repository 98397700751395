import {useSearchParams} from 'react-router-dom';

function useTabSearchParams(tabs = []) {
    const [searchParams, setSearchParams] = useSearchParams();

    const tab = (tabs.some(item=> item.value === searchParams.get("tab")) && searchParams.get("tab")) || tabs[0]?.value;
    const setTab = (newTab) => setSearchParams({tab:newTab});

    return {tab, setTab}
}

export default useTabSearchParams;