import { v4 as uuidv4 } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messageHistory: {},
  lastUpdated: null,
};

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.messageHistory[uuidv4()] = {...action.payload, shown: false};
    },
    setShownNotification: (state, action) => {
      state.messageHistory[action.payload.id] = {...state.messageHistory[action.payload.id], shown: true};
    },
    setLastUpdated: (state, action) => {
      state.lastUpdated = action.payload;
    },
  },
});

export const { setNotification, setShownNotification, setLastUpdated } = notificationSlice.actions;
export default notificationSlice.reducer;
