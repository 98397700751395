import { createAsyncThunk } from '@reduxjs/toolkit';
import API from "../../HOCs/InterceptorsProvider";
import {isResponseOk} from "../../utils/helpers";

const getItem = createAsyncThunk('GET_ITEM', async ({ url }, { getState }) => {
    try {
      const response = await API.get(url)
      if (response?.data) {
        return response.data
      }
    } catch (e) {
      console.log(e)
    }

    const state = getState();
    return state?.item?.selectedItem
});

const setItem = async ({ url, data }) => {
  try {
    const response = await API.post(url, {...data})
      return isResponseOk(response)
  } catch (e) {
    console.log(e)
  }
  return null
};

const updateItem = async ({url, data}) => {
    try {
      const response = await API.put(url, data)
      return isResponseOk(response)
    } catch (e) {
      console.log(e)
    }
    return null
};

const deleteItem = async ({ url }) => {
    try {
        const response = await API.delete(url)
        return isResponseOk(response)
    } catch (e) {
        console.log(e)
    }
    return null
};

export {getItem, setItem, updateItem, deleteItem};
