import { createSlice } from '@reduxjs/toolkit';

import {getTableData} from "../asyncServices/tableService";

const initialState = {
    tableData: [],
    totalCount: 0,
    selectedItems: [],
    tableSettings: {
        page: 0,
        size: 50,
        search: '',
    },
    lastUpdated: null,
    isLoading: 'initialLoading',
}

const tableSlice = createSlice({
    name: 'tableSlice',
    initialState,
    reducers: {
        setSelectedItems: (state, action) => {
            state.selectedItems = action.payload
        },
        setTableSettings: (state, action) => {
            state.tableSettings = {
                ...state.tableSettings,
                ...action.payload,
            }
        },
        setLastUpdated: (state, action) => {
            state.lastUpdated = action.payload
        },
        resetTableState: (state) => ({...initialState, lastUpdated: state.lastUpdated})
    },
    extraReducers: (builder) => {
        builder.addCase(getTableData.pending, (state) => {
            state.isLoading = 'getTableData'
        })
        builder.addCase(getTableData.fulfilled, (state, action) => {
            state.tableData = action.payload.data
            state.totalCount = action.payload.totalCount
            state.isLoading = null
        })
    },
})

export const { setSelectedItems, setTableSettings, setLastUpdated, resetTableState } = tableSlice.actions;
export default tableSlice.reducer;
