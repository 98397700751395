import {useEffect} from "react";
import {useSelector} from "react-redux";
import {Box} from "@mui/material";

import {TABS_DATA} from "../../constants";
import AdminUserTab from "./components/AdminUserTab";

import {TranslationTypography, PageWrapper, TabList, TabPanel} from "../../components";
import useRequests from "../../services/admins/reqestsService";
import AdminRequestsTab from "./components/AdminRequestsTab";
import {useTabSearchParams} from "../../hooks";

export default function AdminPage () {
    const styles = {
        tabList:{
            width: '100%',
            height: '48px',
            typography: 'body1',
            borderBottom: 1,
            borderColor: '#E7E4E4',
            paddingTop: '1rem'
        },
        tableWrapper: {
            maxHeight: '48vh',
            height: '48vh',
        },
        title:{
            margin: '1rem 0'
        }
    }

    const requestCount = useSelector(state => state.common.requestCount);

    const {getCount} = useRequests();

    const adminTabs = [
        {...TABS_DATA.userAdminTab },
        {...TABS_DATA.requestsAdminTab, label: `${TABS_DATA.requestsAdminTab.label} (${requestCount})`}
    ];

    const {tab, setTab} = useTabSearchParams(adminTabs);

    useEffect(()=>{
        getCount()
    }, [tab])

    return(
        <PageWrapper>
            <TranslationTypography
                text={TABS_DATA.userAdminTab.value === tab ? "Admin": "Registration requests"}
                variant='h3'
                sx={styles.title}
            />
            <Box
                component="div"
                sx={styles.tabList}
            >
                <TabList tabs={adminTabs} tab={tab} setTab={setTab}/>
            </Box>
                <TabPanel value={tab} index={TABS_DATA.userAdminTab.value}>
                    <AdminUserTab sx={styles.tableWrapper}/>
                </TabPanel>
                <TabPanel value={tab} index={TABS_DATA.requestsAdminTab.value}>
                    <AdminRequestsTab/>
                </TabPanel>
        </PageWrapper>
    )
};