import { useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import useNotifications from "../services/notificationService";

function SnackbarOutlet() {
  const providerRef = useRef();
  const messageHistory = useSelector((state) => state.notification.messageHistory);
  const lastUpdated = useSelector((state) => state.notification.lastUpdated);

  const {setShown} = useNotifications();

  useEffect(() => {
    if (Object.keys(messageHistory).length) {
        Object.entries(messageHistory).forEach(([key, messageData])=> {
            if (!messageData.shown) {
                providerRef.current.enqueueSnackbar(messageData.message, {
                    variant: messageData.variant,
                });
                setShown(key)
            }
        })
    }
  }, [lastUpdated])

  return (
    <SnackbarProvider
      ref={providerRef}
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Outlet />
    </SnackbarProvider>
  )
}

export default memo(SnackbarOutlet);
