import { useTranslation } from 'react-i18next';
import useTableCellRender from "../useTableCellRender";

const useStatisticsTable = () => {
  const { t } = useTranslation();

  const {renderDate} = useTableCellRender();

  const getColumns = () => [
    {
      field: 'dateTime',
      flex: 2,
      minWidth: 100,
      headerName: t('Date & Time'),
      renderCell: (row) => renderDate(row?.dateTime, "DD/MM/YYYY HH:mm:ss"),
    },
    {
      field: 'name',
      flex: 2,
      minWidth: 200,
      headerName: t('User name'),
    },
    {
      field: 'email',
      flex: 2,
      minWidth: 100,
      headerName: t('Email'),
    },
    {
      field: 'itemExpanded',
      flex: 2,
      minWidth: 100,
      headerName: t('Expanded item name'),
    },
    {
      field: 'expandedItemId',
      flex: 2,
      minWidth: 200,
      headerName: t('Expanded item ID'),
    },
  ]
  return { statisticsColumns: getColumns() }
}

export default useStatisticsTable;
