import {Tabs, Tab} from '@mui/material';

export default function TabList({tabs = [], tab, setTab}){

    const handleChange = (event, newValue) => setTab && setTab(newValue);

    const styles = {
        tabs: {
            "& .MuiTabs-indicator": {
                height: "4px",
                borderRadius: "2px"
            }
        },
        tab: {
            fontWeight: 600,
            color: "light.black"
        },
    }
    return(
        <Tabs
            sx={styles.tabs}
            value={tab}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
        >
            {tabs.map(({sx = {}, value, label, disabled, icon= null, iconPosition= "start"}) => (
                <Tab
                    sx={{...styles.tab, ...sx}}
                    icon={icon}
                    iconPosition={iconPosition}
                    key={`${value}-tab`}
                    value={value}
                    label={label}
                    disabled={!!disabled}
                />
            ))}
        </Tabs>
    )
};