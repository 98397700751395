import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

import {Box, Link} from "@mui/material";
import {LoadingButton} from "@mui/lab";

import {Trace} from "../../icons";
import {PAGES, ROLES} from "../../constants";

import useAuth from "../../services/authService";

import TranslationTypography from "../translation-components/TranslationTypography";
import AdminHeaderTabs from "./AdminHeaderTabs";

export default function Header() {

    const styles = {
        wrapper:{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '1rem 10vw',
            boxSizing: 'border-box',
            minHeight: '73px',
        },
        adminWrapper: {
            display: 'grid',
            gridTemplateColumns: '150px 1fr',
            alignItems: 'center',
            borderBottom: 1,
            borderColor: '#E7E4E4',
            paddingX: "10vw",
            columnGap: "5rem",
            minHeight: '73px',
        },
        header:{
            borderBottom: 1,
            borderColor: '#E7E4E4',
        },
        link:{
            margin: '0 3rem'
        },
    };
    const { pathname } = useLocation()
    const isAuth = useSelector(state=>state.auth.isAuth);
    const role = useSelector((state) => state.auth.user?.role);

    const { logout } = useAuth();

  return(
      <>
          {
              !isAuth && pathname.includes(PAGES.RESET_PASSWORD) && (
                  <Box
                      component="div"
                      sx={{...styles.wrapper, ...styles.header}}
                  >
                      <Link
                          href={`/${PAGES.LOGIN}`}
                          underline="none"
                          sx={styles.link}
                      ><Trace width={150} height={23} /></Link>
                  </Box>)
          }
          {
              role === ROLES.USER && (
              <Box
                  component="div"
                  sx={styles.wrapper}
              >
                  <Trace width={150} height={23} />
                  <LoadingButton color="secondary" onClick={logout}>
                    <TranslationTypography text='Logout'/>
                  </LoadingButton>
              </Box>)
          }
          {
              role === ROLES.ADMIN && (
                  <Box
                      component="div"
                      sx={styles.adminWrapper}
                  >
                      <Trace width={150} height={23} />
                      <AdminHeaderTabs/>
                  </Box>
              )
          }
      </>
  )
}
