import * as React from "react";
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';
import {PAGES} from "../constants";

function PrivateOutlet() {
  const isAuth = useSelector((state) => state.auth.isAuth);
  return isAuth ? <Outlet /> : <Navigate to={PAGES.LOGIN} />;
}

export default React.memo(PrivateOutlet);
