import {useEffect} from "react";
import {useSelector} from "react-redux";

import {Box} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import demoHeaderBackground from "../../images/demo-header-background.png";

import {statusItems, TABS_DATA} from "../../constants";

import {
    TabList,
    TranslationTypography,
    TransTypography,
    RowDataItem,
    PageWrapper,
    TabTable
} from "../../components";
import useItem from "../../services/item/itemService";

import {toFrontendDateFormat} from "../../utils/helpers";
import {useTabSearchParams} from "../../hooks";

export default function DemoPage(){
    const styles = {
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        header: {
            flex: 1,
            maxHeight: '40%',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            padding: '2rem 10vw',
            columnGap: '5%',
            backgroundImage: `url(${demoHeaderBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            justifySelf: 'center',
            color: 'light.white',
            "@media (max-width: 1700px)": {
                gridTemplateColumns: '1.5fr 1fr',
            },
            "@media (max-width: 1500px)": {
                gridTemplateColumns: '3fr 1fr',
            }
        },
        headerInfo:{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: '2rem',
        },
        headerTitle:{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        headerButtons:{
            display: "flex",
            width: '100%',
        },
        headerItemsWrapper:{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '8px',
            "@media (max-width: 1700px)": {
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '6px',
            },
            "@media (max-width: 1500px)": {
                gridTemplateColumns: 'repeat(4, 1fr)',
                gap: '4px',
            }
        },
        main: {
            flex: 1,
            display: 'flex',
            padding: '2rem 10vw',
            bgcolor: 'light.white'
        },
        tabList:{
            width: '100%',
            height: '46px',
            typography: 'body1',
            borderBottom: 1,
            borderColor: '#E7E4E4',
        },
        rowItem: {
            gridTemplateColumns: '1fr 2fr',
            background: 'rgba(255, 255, 255, 0.05)',
            backdropFilter: 'blur(5px)',
            borderRadius: '3px',
            padding: '.4rem .8rem',
            "@media (max-width: 1700px)": {
                gridTemplateColumns: '1fr 1fr',
            }
        },
        text: {
            maxWidth: '300px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        tableWrapper: {
            maxHeight: '30vh',
            height: '30vh',
        }
    };

    const { getItemDataById } = useItem("/persons");
    const person = useSelector(state => state.item.selectedItem);

    const {tab, setTab} = useTabSearchParams(TABS_DATA.personTabs);

    const id = process.env.REACT_APP_DEMO_PERSON_ID;

    const onVisualizeClick = (e) => {
        e.preventDefault()
        window.open(`${process.env.REACT_APP_PUBLIC_VISUALIZE}?id=${id}&type=Person`, '_blank')
    }

    useEffect(()=>{
        if(id) {
            getItemDataById({id})
        }
    }, [id])

    return(
        <PageWrapper
            sx={styles.wrapper}>
            <Box
                component='div'
                sx={styles.header}
            >
                <Box
                    component='div'
                    sx={styles.headerInfo}
                >
                    <Box
                        component='div'
                        sx={styles.headerItemsWrapper}>
                        <RowDataItem field='Name' text={person?.name} sx={styles.rowItem} textSx={styles.text}/>
                        <RowDataItem field='Rating' text={person?.rating} sx={styles.rowItem} textSx={styles.text}/>
                        <RowDataItem field='Gender' text={person?.gender} sx={styles.rowItem} textSx={styles.text}/>
                        <RowDataItem field='Status' text={statusItems[person?.status] ?? ""} sx={styles.rowItem} textSx={styles.text}/>
                        <RowDataItem field='Birth Date' text={toFrontendDateFormat(person?.birthDate)} sx={styles.rowItem} textSx={styles.text}/>
                        <RowDataItem
                            field='Compliance'
                            text={Array.isArray(person?.relationship) ? person.relationship.join(', ') : ""}
                            sx={styles.rowItem}
                            textSx={styles.text}
                        />
                        <RowDataItem field='Address' text={person?.address} sx={styles.rowItem} textSx={styles.text}/>
                    </Box>
                    <TranslationTypography text="demo.text" variant='body1'/>
                    <Box
                        component='div'
                        sx={styles.headerButtons}>
                        <LoadingButton variant="contained" color="secondary" onClick={onVisualizeClick}>
                            <TranslationTypography text="Visualize"/>
                        </LoadingButton>
                    </Box>
                </Box>
                <Box
                    component='div'
                    sx={styles.headerTitle}>
                    <TransTypography i18nKey='demo.title' align='right' variant="h2">
                        Demo data:<br/>{{name:person?.name}}
                    </TransTypography>
                </Box>
            </Box>
            <Box
                component='div'
                sx={styles.main}>
                <Box
                    component='div'
                    sx={styles.tabList}>
                    <TabList tabs={TABS_DATA.personTabs} tab={tab} setTab={setTab}/>
                    {
                        TABS_DATA.personTabs.map(({table, value}) => (
                            tab === value && <TabTable
                                key={`${value}-tab-panel-item`}
                                tab={tab} table={table} value={value}
                                url={`/persons/${id}`}
                                sx={styles.tableWrapper}
                                isDemo
                            />)
                        )
                    }
                </Box>
            </Box>
        </PageWrapper>
    )
}