import {useState} from "react";

function useModal(defaultValue = false){
    const [open, setOpen] = useState(defaultValue);
    const handleClose = () => setOpen(false);
    const handleOpen = (e, value) => setOpen(value || true)
    const handleChangeOpen = () => setOpen(state=>!state);

    return [open, handleOpen, handleClose, handleChangeOpen];
}

export default useModal;