import {memo, useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {useWindowDimensions} from "../../hooks";

import {TABS_DATA} from "../../constants";

import AdminHeaderMenu from "./AdminHeaderMenu";
import TabList from "../TabList";

function AdminHeaderTabs() {
    const styles = {
        wrapper: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            columnGap: '1.5rem',
            height: '100%'
        },
        tab:{
            display: 'flex',
            flexDirection: 'row',
            columnGap: '1.5rem'
        },
        mainTabs:{
            textTransform: 'uppercase',
            fontWeight: 700
        },
        iconTabs:{

        }
    };

    const navigate = useNavigate();
    const currentPath = useSelector(state=>state.common.currentPath);

    const [currentTab, setTab] = useState(TABS_DATA.headerTabs[0].value);

    const {width} = useWindowDimensions();

    const iconTabs = [...TABS_DATA.headerIconTabs, {
        label: <AdminHeaderMenu />,
        value: "settings",
    }];

    const gapTab = width >= 1600 ? [{
        label: "",
        value: ' ',
        sx:{flex: 1},
        disabled: true
    }] : [];

    const tabs = [...TABS_DATA.headerTabs.map(headerTab=>({sx: styles.mainTabs, ...headerTab})), ...gapTab, ...iconTabs];

    function checkValidTab(path){
        return tabs.find((tab)=> tab.value === path || path.includes(tab.value))
    }

    const handleSetTab = (value) => {
        if(value !== "settings") {
            navigate(`/${value}`)
        }
    }

    useEffect(()=>{
        const path = checkValidTab(currentPath);
        if(currentTab !== currentPath && !!path){
            setTab(path.value)
        }
    }, [currentPath]);

    return <TabList tabs={tabs} tab={currentTab} setTab={handleSetTab} />
}

export default memo(AdminHeaderTabs)