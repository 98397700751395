import {Box} from "@mui/material";

import {RowDataItem} from "../../../components";

export default function BankRowData(bank){
    const styles = {
        wrapper: {
            display: 'grid',
            maxWidth: '100%',
            gridTemplateColumns: 'repeat(4, auto)',
            columnGap: '0.5rem',
            alignItems: 'center',
            rowGap: '1rem',
            paddingY: '2.5rem'
        },
        item: {columnGap: '0.3rem',}
    }
    return(
        <Box
            component='div'
            sx={styles.wrapper}>
            <RowDataItem field='Name' text={bank?.name} sx={styles.item}/>
            <RowDataItem field='Address' text={bank?.address} sx={styles.item}/>
            <RowDataItem field='Coordinates' text={bank?.coordinates} sx={styles.item}/>
        </Box>
    )
}