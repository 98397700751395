import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {useTableSearch} from "../../hooks";
import usePersonsTable from "../../services/persons/personsTableService";
import useTables from "../../services/tables/tablesService";

import {
    AppModal,
    ConfirmModal,
    PageWrapper,
    TableHeader,
    TableWithWrapper,
    TranslationTypography
} from "../../components";
import useItem from "../../services/item/itemService";
import useModal from "../../hooks/useModal";
import PersonForm from "../person/components/PersonForm";
import useTags from "../../services/tags/tagsService";
import {prepareDataToBackendFormat} from "../../utils/helpers";

export default function PersonsPage () {

    const navigate = useNavigate();

    const [selectedId, setSelectId] = useState(null);
    const lastUpdated = useSelector(state => state.table.lastUpdated);
    const isLoading = useSelector(state => state.tags.isLoading);

    const { addItemData, deleteItemData } = useItem("/persons");
    const [getPersonTagsData, resetPersonTagsState] = useTags("/person");
    const [getPersonsData, setPersonsTableSettings,,tableLastUpdated] = useTables("/persons");
    const {searchValue, setSearchValue} = useTableSearch();
    const [open, handleOpen, handleClose] = useModal();
    const [openAdd, handleAddOpen, handleAddClose] = useModal();

    const onClickRow = (e, id) => navigate(`./${id}`);

    useEffect(()=>{
        getPersonsData()
    }, [lastUpdated])

    useEffect(()=>{
        getPersonTagsData();
        return () => resetPersonTagsState();
    }, [])

    const onDelete = async (id) => {
        handleOpen()
        setSelectId(id)
    }

    const onCancelDelete = async () => {
        handleClose()
        setSelectId(null)
    }

    const onSubmitDelete = async () => {
        let response = null
        if(selectedId) {
            response = await deleteItemData({id: selectedId})
            if (response){
                tableLastUpdated()
            }
        }
        return response
    }

    const { personsColumns } = usePersonsTable({onDelete});

    const onSubmitForm = async (values) => {
        const response = await addItemData({ data: prepareDataToBackendFormat(values)});
        if (response){
            tableLastUpdated()
        }
        return response
    };

  return(
      <PageWrapper>
          <TranslationTypography text="Persons" variant='h3'/>
          <TableHeader onClick={handleAddOpen} value={searchValue} onChange={setSearchValue}/>
          <TableWithWrapper
              onClickRow={onClickRow}
              columns={personsColumns}
              onTableSettingsChange={setPersonsTableSettings}
              rowHover
          />
          <ConfirmModal
              title="persons.delete"
              open={open}
              onSubmit={onSubmitDelete}
              onClose={onCancelDelete}
          />
          <AppModal  handleClose={handleAddClose} open={openAdd} title="modal.new.person">
              <PersonForm isLoading={!!isLoading} onSubmitForm={onSubmitForm} onCancel={handleAddClose}/>
          </AppModal>
      </PageWrapper>
  )
};