import {useEffect} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";

import { Box } from "@mui/material";

import {TABS_DATA} from "../../constants";
import {useModal, useTableSearch, useTabSearchParams} from "../../hooks";
import useItem from "../../services/item/itemService";

import CompanyRowData from "./components/CompanyRowData";
import {AppModal, PageHeader, PageWrapper, TableForms, TableHeader, TabList, TabTable} from "../../components";

import CompanyForm from "./components/CompanyForm";

import {prepareDataToBackendFormat} from "../../utils/helpers";
import useTags from "../../services/tags/tagsService";

export default function CompanyPage(){
    const styles = {
        tabList:{
            width: '100%',
            height: '48px',
            typography: 'body1',
            borderBottom: 1,
            borderColor: '#E7E4E4',
            paddingTop: '1rem'
        },
        tableWrapper: {
            maxHeight: '30vh',
            height: '30vh',
        }
    }

    const {id} = useParams();
    const company = useSelector(state => state.item.selectedItem);
    const lastUpdated = useSelector(state => state.item.lastUpdated);
    const isLoading = useSelector(state => state.tags.isLoading);

    const {tab, setTab} = useTabSearchParams(TABS_DATA.companyTabs);

    const { getItemDataById, resetItem } = useItem("/companies");
    const {searchValue, setSearchValue} = useTableSearch();
    const [open, handleOpen, handleClose] = useModal();
    const { updateItemData } = useItem("/companies");
    const [getTagsData, resetTagsState] = useTags("/company");

    const onVisualizeClick = (e) => {
        e.preventDefault()
        window.open(`${process.env.REACT_APP_PUBLIC_VISUALIZE}?id=${id}&type=Company`, '_blank')
    }

    const onEditSubmit = (values) => updateItemData({id: company?.id, data: prepareDataToBackendFormat(values)});

    useEffect(()=>{
        if(id){
            getItemDataById({id})
        }
    }, [id, lastUpdated])

    useEffect(()=>{
        if(searchValue) {
            setSearchValue("")
        }
    }, [tab])

    useEffect(()=>{
        if(open === "edit") {
            getTagsData();
        }
    }, [open])

    useEffect(()=>()=> {
        resetItem()
        resetTagsState()
    }, []);

    return(
        <PageWrapper>
            <PageHeader {...company} title="Company" onEdit={(e)=>handleOpen(e, "edit")} onVisualize={onVisualizeClick}/>
            <CompanyRowData {...company}/>
            <Box
                component="div"
                sx={styles.tabList}
            >
                <TabList tabs={TABS_DATA.companyTabs} tab={tab} setTab={setTab}/>
            </Box>
            <TableHeader value={searchValue} onChange={setSearchValue} onClick={(e)=>handleOpen(e, tab)}/>
            {
                TABS_DATA.companyTabs.map(({table, value}) => (
                        tab === value && (
                                <TabTable
                                    key={`${value}-tab-panel-item`}
                                    tab={tab} table={table} value={value}
                                    path='/companies'
                                    url={`/companies/${id}`}
                                    sx={styles.tableWrapper}
                                />
                        ))
                )
            }
            <AppModal
                handleClose={handleClose}
                open={(!!company?.id && !!open)}
                title={open === "edit" ? "modal.edit.company" : `modal.${open}`}
            >
                {open === "edit" && (
                    <CompanyForm
                        {...company}
                        isLoading={!!isLoading}
                        onSubmitForm={onEditSubmit}
                        onCancel={handleClose}
                    />
                )}
                {open && open !== "edit" && (
                    <TableForms
                        id={company?.id}
                        form={open}
                        onCancel={handleClose}
                        path="/companies"
                    />
                )}
            </AppModal>
        </PageWrapper>
    )
}