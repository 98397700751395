import { useTranslation } from 'react-i18next';

import {Trash, Edit} from "../../icons";

const useAdminsUsersTable = ({onEdit, onDelete }) => {
  const { t } = useTranslation();

  const getColumns = () => [
    {
      field: 'name',
      flex: 2,
      minWidth: 200,
      headerName: t('Name'),
    },
    {
      field: 'email',
      flex: 2,
      minWidth: 200,
      headerName: t('Email'),
    },
    {
      field: 'role',
      flex: 2,
      minWidth: 200,
      headerName: t('Role'),
    },
    {
      field: 'id',
      flex: 1,
      minWidth: 100,
      headerName: t('Actions'),
      getActions: (params) => [
        {
          sx: { color: 'light.black' },
          key: `Edit${params.id}`,
          Icon: Edit,
          iconProps:{
            width: '0.7em',
            height: '0.7em',
          },
          onClick: (e) => {
            e.stopPropagation()
            if (onEdit) {
              onEdit(params.id)
            }
          }
        },
        {
          sx: { color: 'light.black' },
          key: `Delete${params.id}`,
          Icon: Trash,
          iconProps:{
            width: '0.7em',
            height: '0.7em',
          },
          onClick: (e) => {
            e.stopPropagation()
            if (onDelete) {
              onDelete(params.id)
            }
          }
        },
      ],
    },
  ]
  return { adminsUsersColumns: getColumns() }
}

export default useAdminsUsersTable
