import {useEffect} from "react";
import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import {TABS_DATA} from "../../constants";

import useNotifications from "../../services/notificationService";
import useAuth from "../../services/authService";
import useItem from "../../services/item/itemService";

import {TranslationTypography, PageWrapper, TabList, TabPanel} from "../../components";
import SettingsForm from "./components/SettingsForm";
import ResetPasswordForm from "../reset-password/ResetPasswordForm";
import ProfileForm from "./components/ProfileForm";
import {useTabSearchParams} from "../../hooks";

export default function SettingsPage () {

    const styles = {
        wrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        tabList:{
            width: '100%',
            height: '48px',
            typography: 'body1',
            borderBottom: 1,
            borderColor: '#E7E4E4',
            paddingTop: '1rem'
        },
        title:{
            margin: '1rem 0'
        },
    };

    const {tab, setTab} = useTabSearchParams(TABS_DATA.settingsTabs);

    const item = useSelector(state => state.item.selectedItem);
    const isLoadingItem = useSelector(state => state.item.isLoading);
    const isLoading = useSelector(state => state.auth.isLoading === 'changePassword');

    const { notification } = useNotifications();

    const { changePassword } = useAuth();
    const { getItemDataById, updateItemData, resetItem } = useItem();

    const onSubmitForm = async (values) => {
        let response;
        if(tab === TABS_DATA.settingsTabs[0].value){
            response = await updateItemData({url:"/users/setup", data: {...values}})
        }

        if(tab === TABS_DATA.settingsTabs[1].value){
            response = await updateItemData({url:"/users/profile", data: {...values}})
        }

        if(tab === TABS_DATA.settingsTabs[2].value){
            response = await changePassword({...values})
            if(response){
                notification({message:"resetPassword.setPassword"});
            }
        }

        return response
    };

    const getTabData = async () => {
        if(tab === TABS_DATA.settingsTabs[0].value){
            await getItemDataById({url:"/users/setup"})
        }

        if(tab === TABS_DATA.settingsTabs[1].value){
            await getItemDataById({url:"/users/profile"})
        }
    }

    useEffect(()=>{
        resetItem()
        if(tab !== TABS_DATA.settingsTabs[2].value){
            getTabData()
        }
    }, [tab])

    return(
        <PageWrapper>
            <TranslationTypography
                text={TABS_DATA.settingsTabs.find(currentTab=>currentTab.value === tab)?.label || ""}
                variant='h3'
                sx={styles.title}
            />
            <Box
                component="div"
                sx={styles.tabList}
            >
                <TabList tabs={TABS_DATA.settingsTabs} tab={tab} setTab={setTab}/>
            </Box>
            <TabPanel
                value={tab} index={TABS_DATA.settingsTabs[0].value}
            >
                <SettingsForm
                    {...item}
                    isLoading={isLoadingItem === "getItem"}
                    loading={isLoadingItem === "updateItem"}
                    onSubmitForm={onSubmitForm}
                />
            </TabPanel>
            <TabPanel
                value={tab} index={TABS_DATA.settingsTabs[1].value}
            >
                <Box
                    component="div"
                    sx={styles.wrapper}
                >
                    <ProfileForm
                        isLoading={isLoadingItem === "getItem"}
                        {...item}
                        loading={isLoadingItem === "updateItem"}
                        onSubmitForm={onSubmitForm}
                    />
                </Box>
            </TabPanel>
            <TabPanel
                value={tab} index={TABS_DATA.settingsTabs[2].value}
            >
                <Box
                    component="div"
                    sx={styles.wrapper}
                >
                    <ResetPasswordForm loading={isLoading} onSubmitForm={onSubmitForm} />
                </Box>
            </TabPanel>
        </PageWrapper>
    )
};