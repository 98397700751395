import { useState } from 'react';
import { useController } from 'react-hook-form';

import {
  FormControl,
  FormHelperText,
  InputAdornment,
  IconButton,
  TextField
} from '@mui/material';
import { VisibilityOff, Visibility } from '@mui/icons-material';

export default function FormInputPassword({
  id = null,
  label = '',
  control,
  size,
  name = 'password',
  errors = '',
  ...rest
}) {

  const {
    field: { ref, onChange, onBlur, ...controllerProps },
  } = useController({
    name,
    control,
    defaultValue: '',
  });

  const props = { ...controllerProps, ...rest };

  const [isShowPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((state) => !state)
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  };

  const styles = {
    formControl:{
      marginBottom: '25px'
    },
    error:{
      position: 'absolute',
      top: '32px',
      whiteSpace: 'nowrap'
    },
    input: {
      borderRadius: '3px',
      bgcolor: 'light.white',
      "& .MuiOutlinedInput-root": {
        "& > fieldset": {
          border: "none"
        }
      }
    },
  };

  return (
    <FormControl fullWidth sx={styles.formControl} variant="outlined">
      <TextField
        id={id || `input-${name}`}
        inputRef={ref}
        onBlur={onBlur}
        onChange={onChange}
        error={!!errors}
        size={size}
        label={label}
        type={isShowPassword ? 'text' : 'password'}
        name={name}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end">
                {isShowPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={styles.input}
        {...props}
      />

      {errors && (
        <FormHelperText sx={styles.error} error id={`error-${name}`}>
          {errors?.message}
        </FormHelperText>
      )}
    </FormControl>
  )
};
