import {memo} from "react";
import {Box, Typography} from "@mui/material";
import TranslationTypography from "./translation-components/TranslationTypography";
import ButtonTranslation from "./ButtonTranslation";
import {EDIT, VISUALIZE} from "../constants/buttons";

function PageHeader({name, title = "", onEdit= null, onVisualize = null}) {
    const styles = {
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        headerTextWrapper: {
            display: 'flex',
            flexDirection: 'column',
            rowGap : '0.5rem',

        },
        headerText: {textTransform: 'uppercase'},
        headerName: {
            maxWidth: "50vw",
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        headerButtonsWrapper: {
            display: 'flex',
            flexDirection: 'row',
            columnGap: '1rem'
        }
    }
    return(
        <Box
            component="div"
            sx={styles.header}
        >
            <Box
                component="div"
                sx={styles.headerTextWrapper}
            >
                <TranslationTypography sx={styles.headerText} color='secondary.text' text={title} variant='body2'/>
                <Typography sx={styles.headerName} variant='h2'>
                    {name}
                </Typography>
            </Box>
            <Box
                component="div"
                sx={styles.headerButtonsWrapper}
            >
                {onEdit && <ButtonTranslation onClick={onEdit} key={EDIT.text} {...EDIT}/>}
                {onVisualize && <ButtonTranslation onClick={onVisualize} key={VISUALIZE.text} {...VISUALIZE}/>}
            </Box>
        </Box>
    )
}

export default memo(PageHeader)