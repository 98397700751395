import * as React from "react";

function More(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M1 1v6m0 0h6M1 7l2.64-2.37a9 9 0 0114.574 2.692M19 19v-6m0 0h-6m6 0l-2.64 2.37a9 9 0 01-14.574-2.692"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoMore = React.memo(More);
export default MemoMore;
