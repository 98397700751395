import {memo} from "react";
import {useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";

import * as Form from "../form-components";
import {withLoading} from "../../HOCs";

function CompanyForm(props) {

    const { t } = useTranslation();

    const {onCancel, onSubmitForm, form} = props;

    const tags = useSelector(state => state.tags.tags);
    const loading = useSelector(state => state.item.isLoading === "setItem" || state.item.isLoading === 'updateItem');

    const getFieldByForm = (currentForm) =>{
        const fieldItem = {}
        const fieldValue = {}

        if(currentForm === "companies"){
            fieldValue.company = props?.company ?? ""
            fieldItem.company = yup.string().required(t('required'))
        }

        if(currentForm === "employee"){
            fieldValue.person = props?.person ?? ""
            fieldItem.person = yup.string().required(t('required'))
        }

        return {fieldItem, fieldValue}
    }

    const {fieldItem, fieldValue} = getFieldByForm(form);

    const schema = yup.object().shape({
        function: yup.string().required(t('required')),
        sinceDate: yup.date().required(t('required')),
        leftDate: yup.date().required(t('required')),
        ...fieldItem
    });

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            function: props?.function ?? "",
            sinceDate: props?.sinceDate ?? "",
            leftDate: props?.leftDate ?? "",
            ...fieldValue
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = async (values)=>{
        const response = await onSubmitForm(values);
        if(response) {
            onCancel()
        }
        return response
    }

    return (
        <Form.FormWrapper onSubmit={handleSubmit(onSubmit)}>
            {form === "companies" && <Form.FormSelect
                displayEmpty
                id="companies-company"
                name="company"
                items={tags.map(item => ({value: item.id, label: t(item.name)}))}
                control={control}
                size="small"
                errors={errors.company}
                placeholder={t('Company')}
            />}
            {form === "employee" && <Form.FormSelect
                displayEmpty
                id="companies-person"
                name="person"
                items={tags.map(item=>({value: item.id, label: t(item.name)}))}
                control={control}
                size="small"
                errors={errors.person}
                placeholder={t('Person')}
            />}
            <Form.FormInput
                id="companies-function"
                name="function"
                control={control}
                size="small"
                errors={errors.function}
                placeholder={t('Function')}
            />
            <Form.FormDatePicker
                id="companies-sinceDate"
                name="sinceDate"
                control={control}
                errors={errors.sinceDate}
                placeholder={t('Since')}
            />
            <Form.FormDatePicker
                id="companies-leftDate"
                name="leftDate"
                control={control}
                errors={errors.leftDate}
                placeholder={t('Left')}
            />
            <Form.FormButtons loading={loading} onCancel={onCancel}/>
        </Form.FormWrapper>
    )
}

export default memo(withLoading(CompanyForm))