import Typography from "@mui/material/Typography";
import { useTranslation } from 'react-i18next';

export default function TranslationTypography ({text, prefix = '', postfix='', ...rest}) {
    const { t } = useTranslation();
  return(
      <Typography {...rest}>
          {prefix + t(text) + postfix}
      </Typography>
  )
};