import {Box} from "@mui/material";

import {RowDataItem} from "../../../components";
import {toFrontendDateFormat} from "../../../utils/helpers";
import {statusItems} from "../../../constants";

export default function PersonRowData(person){
    const styles = {
        wrapper: {
            display: 'grid',
            maxWidth: '70%',
            gridTemplateColumns: 'repeat(4, auto)',
            columnGap: '0.5rem',
            alignItems: 'center',
            rowGap: '1rem',
            paddingY: '2.5rem',
            "@media (max-width: 1400px)": {
                maxWidth: '100%',
            },
        },
        item: {columnGap: '0.5rem',}
    }
    return(
        <Box
            component='div'
            sx={styles.wrapper}>
            <RowDataItem field='Name' text={person?.name} sx={styles.item}/>
            <RowDataItem field='Address' text={person?.address} sx={styles.item}/>
            <RowDataItem field='Status' text={statusItems[person?.status] ?? ""} sx={styles.item}/>
            <RowDataItem field='Birth Date' text={toFrontendDateFormat(person?.birthDate)} sx={styles.item}/>
            <RowDataItem field='Gender' text={person?.gender} sx={styles.item}/>
            <RowDataItem field='Coordinates' text={person?.coordinates} sx={styles.item}/>
            <RowDataItem field='Rating' text={person?.rating} sx={styles.item}/>
            <RowDataItem field='Compliance' text={Array.isArray(person?.relationship) && person.relationship.join(', ')} sx={styles.item}/>
        </Box>
    )
}