import axios from 'axios';
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {useDispatch} from "react-redux";

import useNotifications from "../services/notificationService";
import {LOG_OUT} from "../constants";

const API = axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_URL,
    responseType: 'json',
    headers: {
        "cache": 'no-store',
    },
});

const InterceptorsProvider = ({ children }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { setNotificationData, notificationLastUpdated, notification } = useNotifications();

    useEffect(() => {

        const resInterceptor = response => response

        const errInterceptor = error => {
            if ((error.config && error.response?.status === 401) ||
                error.response?.status === 500 ||
                error.code === "ERR_NETWORK") {
                dispatch({ type: LOG_OUT })
            }

            if (error.config && error.response?.data) {

                if(typeof error.response.data === 'string'){
                    notification({message: error.response.data, variant: 'warning'})
                }

                if(Array.isArray(error.response.data)) {
                    error.response.data.map(
                        alert => typeof alert === 'string' && setNotificationData({
                            message: alert,
                            variant: 'warning'
                        }));
                    notificationLastUpdated()
                }
            }

            return Promise.reject(error);
        }

        const interceptor = API.interceptors.response.use(resInterceptor, errInterceptor);

        return () => API.interceptors.response.eject(interceptor);

    }, [navigate])

    return children;
}

export default API;
export { InterceptorsProvider }
