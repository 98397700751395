import * as React from "react";

function Wiki(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 20 14" fill="none" {...props}>
      <path
        d="M15.39.11v.35c-.463.082-.813.228-1.05.436-.341.31-.743.784-1.006 1.422l-5.36 10.94h-.357L2.237 2.176c-.251-.569-.592-.916-.695-1.043A1.686 1.686 0 00.95.673C.715.563.4.492 0 .46V.11h5.236v.35c-.604.057-.892.158-1.072.304a.687.687 0 00-.27.559c0 .316.148.809.443 1.479l3.974 7.536 3.886-7.441c.302-.734.552-1.242.552-1.527 0-.183-.093-.358-.28-.526-.186-.167-.397-.286-.84-.355a6.133 6.133 0 00-.164-.029V.11h3.925z"
        fill="currentColor"
      />
      <path
        d="M20 .11v.35c-.463.082-.813.228-1.051.436-.34.31-.742.784-1.006 1.422l-4.704 10.94h-.356L7.912 2.176c-.251-.569-.592-.916-.695-1.043a1.686 1.686 0 00-.592-.46C6.39.563 6.178.492 5.779.46V.11h5.132v.35c-.604.057-.892.158-1.072.304a.687.687 0 00-.27.559c0 .316.148.809.444 1.479l3.563 7.536 3.23-7.441c.302-.734.552-1.242.552-1.527 0-.183-.093-.358-.279-.526-.186-.167-.501-.286-.945-.355A6.208 6.208 0 0015.97.46V.11H20z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoWiki = React.memo(Wiki);
export default MemoWiki;
