import {memo} from "react";
import Box from '@mui/material/Box'
import Loader from './loader/Loader'

function LoadingComponent({sx = {}, ...props}) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: 'light.transparentGray',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
          ...sx
      }}>
      <Loader {...props} />
    </Box>
  )
}

export default memo(LoadingComponent);
