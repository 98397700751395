import LoadingComponent from "../components/LoadingComponent";

function withLoading(Component) {
    const styles = {
        loading: {
            width: "30vw",
            height: "400px",
            position: "static",
            backgroundColor: 'light.transparent',
        }
    };

    return function WithLoadingForm({isLoading = false, ...props}){
        return isLoading ? <LoadingComponent sx={styles.loading}/> : <Component {...props}/>
    }
}

export default withLoading;