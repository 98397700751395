import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox
} from '@mui/material';

import AppTableHeadCell from './AppTableHeadCell';

export default function AppTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    onRequestFilter,
    columns,
    filters,
    selected,
    tableSettings,
  } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const createFilterHandler = (property) =>
    function (values) {
      if (property === null) {
        return null
      }
      return onRequestFilter(property, values)
    }

  return (
    <TableHead>
      <TableRow>
        {selected && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
        )}
        {columns.map((column) => (
          <AppTableHeadCell
            key={column.field}
            tableSettings={tableSettings}
            filters={filters[column?.filterName]}
            orderBy={orderBy}
            order={order}
            createSortHandler={createSortHandler}
            createFilterHandler={createFilterHandler}
            onRequestFilter={onRequestFilter}
            {...column}
          />
        ))}
      </TableRow>
    </TableHead>
  )
}
