import * as React from "react";

function Edit(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M1 14l-.354-.354-.146.147V14H1zM14 1l.354-.354a.5.5 0 00-.708 0L14 1zm4 4l.354.354a.5.5 0 000-.708L18 5zM5 18v.5h.207l.147-.146L5 18zm-4 0H.5a.5.5 0 00.5.5V18zm.354-3.646l13-13-.708-.708-13 13 .708.708zm12.292-13l4 4 .708-.708-4-4-.708.708zm4 3.292l-13 13 .708.708 13-13-.708-.708zM5 17.5H1v1h4v-1zm-3.5.5v-4h-1v4h1zm9.146-13.646l4 4 .708-.708-4-4-.708.708z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoEdit = React.memo(Edit);
export default MemoEdit;
