import {memo, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ConfirmModal, TableWithWrapper} from "../../../components";
import useTables from "../../../services/tables/tablesService";
import useItem from "../../../services/item/itemService";
import useModal from "../../../hooks/useModal";
import useAdminsRequestsTable from "../../../services/admins/requestsAdminsTableService";
import useRequests from "../../../services/admins/reqestsService";

function AdminRequestsTab() {
    const lastUpdated = useSelector(state => state.table.lastUpdated);
    const [selectedId, setSelectId] = useState(null);
    const {getCount} = useRequests();
    const [open, handleOpen, handleClose] = useModal();
    const { approveItem, deleteItemData } = useItem(`/registration/requests`);
    const [ getRequestsData, setRequestsTableSettings,, tableLastUpdated ] = useTables("/registration/requests");

    const onAction = async (id, actionName) => {
        handleOpen(null, actionName)
        setSelectId(id)
    }

    const onSubmitAction = async () => {
        let response;

        if(selectedId && open === 'reject') {
            response = await deleteItemData({id: selectedId})
        }else {
            response = await approveItem({id: selectedId})
        }

        if(response) {
            tableLastUpdated()
            getCount()
        }

        return response
    }

    const onCancelAction = () => {
        handleClose()
        setSelectId(null)
    }

    useEffect(()=>{
        getRequestsData()
    }, [lastUpdated]);

    const {adminsRequestsColumns} = useAdminsRequestsTable({
        onApprove: (id)=> onAction(id, 'approve'),
        onReject: (id)=> onAction(id, 'reject'),
    });
    return(
        <>
            <TableWithWrapper
                columns={adminsRequestsColumns}
                onTableSettingsChange={setRequestsTableSettings}
            />
            <ConfirmModal
                title={open === 'reject' ? "admin.requestReject": "admin.requestApprove"}
                open={open}
                onSubmit={onSubmitAction}
                onClose={onCancelAction}
            />
        </>

    )
}

export default memo(AdminRequestsTab)