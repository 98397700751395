import {useTranslation} from "react-i18next";

import { TextField, InputAdornment } from '@mui/material';
import {Search} from "../icons";

export default function InputSearch({id, sx={}, onChange, ...rest}){
    const styles = {
        input: {
            bgcolor: 'primary.backgroundColor',
            minWidth: '200px',
            width: '25%',
            "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                    border: "none"
                }
            },
            ...sx
        },
    }
    const handleChange = (e) => onChange(e.target.value);

    const { t } = useTranslation();
    return(
        <TextField
            id={id || "input-search"}
            variant="outlined"
            sx={styles.input}
            onChange={handleChange}
            placeholder={t('Search')}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <Search width='1.5em' height='1.5em'/>
                    </InputAdornment>
                ),
            }}
            {...rest}
        />
    )
}