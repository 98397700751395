import { createAsyncThunk } from '@reduxjs/toolkit';
import API from "../../HOCs/InterceptorsProvider";
import {LOG_OUT} from "../../constants";
import {isResponseOk} from "../../utils/helpers";

export const authLogin = createAsyncThunk('AUTH_LOGIN', async ({email, password}, {getState}) => {
  try {
    const response = await API.post('auth/login', { email, password});
    if (isResponseOk(response)) {
      return { isAuth: true, user: response.data };
    }
  } catch (error) {
    console.warn(error.message)
  }

  const state = getState();
  return {
    isAuth: state?.auth?.isAuth,
    user: state?.auth?.user,
  };
});

export const authResetPassword = async ({email}) => {
  try {
    const response = await API.post('auth/reset-password', {email});
    return isResponseOk(response)
  } catch (error) {
    console.warn(error.message)
  }
  return null;
};

export const authResetPasswordConfirm = async ({ newPassword, confirmPassword, token}) => {
  try {
    const response = await API.put(`auth/reset-password/${token}`, {newPassword, confirmPassword});
    return isResponseOk(response)
  } catch (error) {
    console.warn(error.message)
  }
  return null;
};

export const authChangePassword = async ({ oldPassword, newPassword, confirmPassword}) => {
  try {
    const response = await API.post("auth/change-password", {oldPassword, newPassword, confirmPassword});
    return isResponseOk(response)
  } catch (error) {
    console.warn(error.message)
  }
  return null;
};

export const authLogout = createAsyncThunk('AUTH_LOGOUT', async (_, {dispatch}) => {
  try {
    dispatch({ type: LOG_OUT })
    await API.post('auth/logout');
  } catch (error) {
    console.warn(error.message)
  }
});

export const authRegister = async ({ name, email, password}) => {
  try {
    const response = await API.post('/registration', {name, email, password});
    return isResponseOk(response) && response.data;
  } catch (error) {
    console.warn(error)
  }
  return null
};
