import { Routes, Route, Navigate } from 'react-router-dom';

import {
    PrivateOutlet,
    PrivateRolesOutlet,
    SnackbarOutlet,
    WithNavigationPathName,
} from "./HOCs";

import { Layout } from "./components";

import {PAGES} from "./constants";

import {
    LoginPage,
    PersonsPage,
    DemoPage,
    CompaniesPage,
    WikiPage,
    AdminPage,
    AboutPage,
    SettingsPage,
    StatisticsPage,
    PersonPage,
    BanksPage,
    CompanyPage,
    ResetPassword,
    BankPage,
    ResetPasswordConfirm
} from "./pages";

function App() {
  return (
      <Routes>
          <Route element={<SnackbarOutlet />}>
              <Route element={<WithNavigationPathName />}>
                  <Route element={<Layout />}>
                      <Route element={<PrivateOutlet />}>
                          <Route path={PAGES.DEMO} element={<DemoPage/>} />
                          <Route element={<PrivateRolesOutlet />}>
                              <Route index element={<Navigate replace to={PAGES.PERSONS} />} />
                              <Route path={PAGES.PERSONS} element={<PersonsPage/>} />
                              <Route path={PAGES.PERSON} element={<PersonPage/>} />
                              <Route path={PAGES.COMPANIES} element={<CompaniesPage/>} />
                              <Route path={PAGES.COMPANY} element={<CompanyPage/>} />
                              <Route path={PAGES.BANKS} element={<BanksPage/>} />
                              <Route path={PAGES.BANK} element={<BankPage/>} />
                              <Route path={PAGES.WIKI} element={<WikiPage/>} />
                              <Route path={PAGES.ADMIN} element={<AdminPage/>} />
                              <Route path={PAGES.ABOUT} element={<AboutPage/>} />
                              <Route path={PAGES.SETTINGS} element={<SettingsPage/>} />
                              <Route path={PAGES.STATISTICS} element={<StatisticsPage/>} />
                          </Route>
                      </Route>
                      <Route path={PAGES.LOGIN} element={<LoginPage/>} />
                      <Route path={PAGES.RESET_PASSWORD} element={<ResetPassword/>} />
                      <Route path={PAGES.RESET_PASSWORD_CONFIRM} element={<ResetPasswordConfirm/>} />
                      <Route path="*" element={<Navigate replace to={PAGES.LOGIN} />} />
                  </Route>
              </Route>
          </Route>
      </Routes>
  );
}

export default App;
