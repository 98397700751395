import { useTranslation } from 'react-i18next';

import {
  Table,
  TableContainer,
  TablePagination,
  Paper,
  TableRow,
  TableCell,
  TableBody,
  Box
} from '@mui/material';

import {More} from "../../icons";

import AppTableHead from './AppTableHead';
import AppTableBody from './AppTableBody';
import ButtonTranslation from "../ButtonTranslation";
import LoadingComponent from "../LoadingComponent";

export default function AppTable(props) {
  const { t } = useTranslation();

  const {
    order = null,
    orderBy = null,
    loading = false,
    columns = [],
    rows = [],
    filters = {},
    pageSize = 5,
    page = 1,
    totalCount = 0,
    onTableSettingsChange = null,
    selected,
    onSelectionModelChange = null,
    labelPageSize = t('labelPageSizeDefault'),
    rowsPerPageOptions = [5, 10, 25, 50, 100, 500, 1000],
    footerText = '',
    showPagination = !!rows.length,
    showLoadMore = false,
    showFooterText = false,
    tableSettings,
    isErrorValueRow = false,
    onClickRow = null,
    rowHover = false,
    sx = {}
  } = props;

  const styles = {
    wrapper: {
      width: '100%',
      overflowY: 'hidden',
      position: 'relative',
      overflowX: 'auto',
      height: '100%',
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'none'
    },
    container: {
      flexGrow: 1,
      position: 'relative',
      ...sx
    },
    loadMore:{
      paddingTop: '1rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }

  const onSelectionChange = (data) => {
    if (onSelectionModelChange) {
      onSelectionModelChange(data)
    }
  }

  const onSettingsChange = async (data) => {
    if (onTableSettingsChange) {
      await onTableSettingsChange(data)
    }
  }

  const onChangeSort = (currentField, field, direction) => {
    if (currentField !== field) {
      return { s_direction: 'asc', s_field: currentField }
    }

    if (direction === 'asc') {
      return { s_direction: 'desc', s_field: currentField }
    }

    return { s_direction: null, s_field: null }
  }

  const handleRequestSort = (event, property) => {
    const data = onChangeSort(property, orderBy, order)
    onSettingsChange(data)
  }

  const handleRequestFilter = (property, values = []) => {
    onSettingsChange({ [property]: values.length > 0 ? values : null })
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id)
      onSelectionChange(newSelecteds)
      return
    }
    onSelectionChange([])
  }

  const handleClickRow = (event, id) => {
    if (selected === undefined) {
      onSelectionChange(null)
    } else {
      const selectedIndex = selected.indexOf(id)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        )
      }

      onSelectionChange(newSelected)
    }
  }

  const handleChangePage = (event, newPage) => {
    onSettingsChange({ page: newPage })
  }

  const handleChangeRowsPerPage = (event) => {
    const currentPageSize = parseInt(event.target.value, 10)
    onSettingsChange({ page: 0, size: currentPageSize })
  }

  const isSelected = (name) => selected && selected.indexOf(name) !== -1

  return (
    <Paper sx={styles.wrapper}>
      <TableContainer sx={styles.container}>
        {loading !== 'initialLoading' &&
            <>
              {!!loading && <LoadingComponent/>}
              <Table>
                <AppTableHead
                    filters={filters}
                    tableSettings={tableSettings}
                    columns={columns}
                    numSelected={selected && selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    onRequestFilter={handleRequestFilter}
                    rowCount={totalCount}
                    selected={selected}
                />
                <AppTableBody
                    loading={loading}
                    handleClickRow={onClickRow || handleClickRow}
                    isSelected={isSelected}
                    columns={columns}
                    rows={rows}
                    selected={selected}
                    isErrorValueRow={isErrorValueRow}
                    rowHover={rowHover}
                />
              </Table>
            </>
        }
      </TableContainer>

      {showFooterText && (
        <Table align="right">
          <TableBody>
            <TableRow>
              <TableCell align="right" colSpan={4}>
                <b>{footerText}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {showPagination && (
        <TablePagination
          labelRowsPerPage={labelPageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={totalCount}
          rowsPerPage={pageSize}
          page={rows.length > 0 && totalCount > pageSize ? page : 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {showLoadMore && !showPagination && rows.length > 0 && totalCount > rows.length && (
          <Box
              component="div"
              sx={styles.loadMore}
          >
            <ButtonTranslation
                color='primary'
                variant='contained'
                startIcon={<More />}
                text="Load more"
                onClick={(e)=>handleChangePage(e, page + 1)}
            />
          </Box>
      )}
    </Paper>
  )
}
