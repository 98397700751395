import * as React from "react";
import { useSelector } from 'react-redux';
import { Outlet, Navigate } from 'react-router-dom';

import {PAGES, ROLES} from "../constants";

function PrivateRolesOutlet() {
  const role = useSelector((state) => state.auth.user.role);
  return role === ROLES.ADMIN ? <Outlet /> : <Navigate to={PAGES.DEMO} />;
}

export default React.memo(PrivateRolesOutlet);