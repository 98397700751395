import {memo} from "react";
import {useSelector} from "react-redux";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {useTranslation} from "react-i18next";

import {Form} from "../../../components";

function BankForm(props) {

    const { t } = useTranslation();

    const { onCancel, onSubmitForm } = props;
    
    const loading = useSelector(state => state.item.isLoading === "setItem" || state.item.isLoading === 'updateItem');
    
    const schema = yup.object().shape({
        name: yup.string().required(t('required')),
        address: yup.string().required(t('required')),
        coordinates: yup.string(),
    });

    const {
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        defaultValues: {
            name : props?.name ?? "",
            address: props?.address ?? "",
            coordinates: props?.coordinates ?? "",
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = async (values)=>{
        const response = await onSubmitForm(values);
        if(response) {
            onCancel()
        }
        return response
    }

    return (
        <Form.FormWrapper
            onSubmit={handleSubmit(onSubmit)}
        >
            <Form.FormInput
                id="bank-name"
                name="name"
                control={control}
                size="small"
                errors={errors.name}
                placeholder={t('Name')}
            />
            <Form.FormInput
                id="bank-address"
                name="address"
                control={control}
                size="small"
                errors={errors.address}
                placeholder={t('Address')}
            />
            <Form.FormInput
                id="bank-coordinates"
                name="coordinates"
                control={control}
                size="small"
                errors={errors.coordinates}
                placeholder={t('Coordinates')}
            />
            <Form.FormButtons loading={loading} onCancel={onCancel}/>
        </Form.FormWrapper>
    )
}

export default memo(BankForm)