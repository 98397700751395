import * as React from "react";

function Ellipse(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 10 10" fill="none" {...props}>
      <circle cx={5} cy={5} r={5} fill="currentColor" />
    </svg>
  );
}

const MemoEllipse = React.memo(Ellipse);
export default MemoEllipse;
