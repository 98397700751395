import * as React from "react";

function Search(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 19 19" fill="none" {...props}>
      <path
        d="M17.646 18.354a.5.5 0 00.708-.708l-.708.708zM15.5 8.5a7 7 0 01-7 7v1a8 8 0 008-8h-1zm-7 7a7 7 0 01-7-7h-1a8 8 0 008 8v-1zm-7-7a7 7 0 017-7v-1a8 8 0 00-8 8h1zm7-7a7 7 0 017 7h1a8 8 0 00-8-8v1zm9.854 16.146l-4.188-4.187-.707.707 4.187 4.188.708-.708z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoSearch = React.memo(Search);
export default MemoSearch;
