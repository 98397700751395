import {useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";

import {Box} from '@mui/material';

import useAuth from "../../services/authService";
import {TranslationTypography} from '../../components';
import useNotifications from "../../services/notificationService";
import {PAGES} from "../../constants";
import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPasswordConfirm () {
    const styles = {
        wrapper: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        title:{
           margin: '1rem 0'
        },
    };

    const {token} = useParams();
    const navigate = useNavigate();
    const isLoading = useSelector(state => state.auth.isLoading === "resetPasswordConfirm");

    const { notification } = useNotifications();

    const { resetPasswordConfirm } = useAuth();

    const onSubmitForm = async (values) => {
        const response = await resetPasswordConfirm({token, ...values})
        if(response){
            notification({message:"resetPassword.setPassword"});
            navigate(PAGES.LOGIN)
        }
    };

  return (
        <Box
            component="div"
            sx={styles.wrapper}
        >
            <TranslationTypography sx={styles.title} variant="h2" text="Change password"/>
            <ResetPasswordForm loading={isLoading} onSubmitForm={onSubmitForm} token={token}/>
        </Box>
    );
};
