import { useDispatch } from 'react-redux';

import {deleteItem, getItem, setItem, updateItem} from "../../redux/asyncServices/itemService";
import {resetItemState, setLastUpdated, setLoading} from "../../redux/slices/itemSlice";
import useNotifications from "../notificationService";

function useItem(path="/") {
  const dispatch = useDispatch();
  const { notification } = useNotifications();
  const itemLastUpdated = () => dispatch(setLastUpdated(Date.now()));
  const itemLoading = (isLoading) => dispatch(setLoading(isLoading));
  const resetItem = () => dispatch(resetItemState());

  const getItemDataById = ({ url = null, id }) => {
    if(url || id) {
      dispatch(getItem({url: url || `${path}/${id}`}))
    }
  };

  const updateItemData = async ({url = null, id, data, message = "Successfully updated"}) => {
    let response = null;

    if(url || id) {
      itemLoading('updateItem');
      response = await updateItem({url: url || `${path}/${id}`, data});
      itemLoading(null);
      if(response){
        notification({message});
        itemLastUpdated()
      }
    }

    return response
  };

  const addItemData = async ({url = null, data, message = "Added successfully"}) => {
    itemLoading('setItem');
    const response = await setItem({url: url || path, data});
    itemLoading(null);
    if(response){
      notification({message});
    }
    return response
  };

  const approveItem = async ({id, message= "Request approved"}) => {
    itemLoading('approveItem');
    const response = await setItem({url: `${path}/${id}`});
    itemLoading(null);
    if(response){
      notification({message});
    }
    return response
  };

  const deleteItemData = async ({url = null, id = null, message = "Successfully deleted"}) => {
    let response = null;

    if(url || id) {
      itemLoading('deleteItem');
      response = await deleteItem({url: url || `${path}/${id}`});
      itemLoading(null);
      if(response){
        notification({message});
      }
    }
    return response
  };

  return {
    getItemDataById,
    deleteItemData,
    updateItemData,
    addItemData,
    itemLastUpdated,
    approveItem,
    resetItem
  }
}

export default useItem;
