import {memo} from "react";
import {Box, Modal, IconButton} from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import TranslationTypography from "./translation-components/TranslationTypography";

function AppModal({children, open, handleClose, title = "",  ...rest}){
    const styles = {
        wrapper: {
            position: 'absolute',
            width: "100%",
            height: "100%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        main: {
            display: 'flex',
            flexDirection: 'column',
            minWidth: "30vw",
            padding: '2rem',
            position: 'relative',
            bgcolor: 'primary.backgroundColor',

        },
        closeIcon: {
            position: 'absolute',
            right: 0,
            top: 0
        },
        content:{
            width: '100%',
            maxHeight: "75vh",
            overflowY: 'auto',
        },
        title:{
            marginBottom: "2rem"
        },
    }
    return(
        <Modal
            open={open}
            onClose={handleClose}
            {...rest}
        >
            <Box
                component="div"
                sx={styles.wrapper}>
                <Box
                    component="div"
                    sx={styles.main}
                >
                    <IconButton sx={styles.closeIcon} onClick={handleClose}>
                        <CloseIcon/>
                    </IconButton>
                    {title && <TranslationTypography sx={styles.title} text={title} variant='h3'/>}
                    <Box
                        component="div"
                        sx={styles.content}
                    >
                        {children}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default memo(AppModal);