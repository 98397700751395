import moment from "moment";
import { en } from '../constants';

export const getNavigatorLanguage = () => {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  }
  return (
    navigator.userLanguage ||
    navigator.language ||
    navigator.browserLanguage ||
    'en'
  );
};

export const getLanguage = () => en;

export const  getHtmlAlerts = (htmlDoc) => {
  const alertsList = htmlDoc.querySelectorAll(".alert:not( .civ-invisible )");
  const alerts = []
  if(alertsList.length > 0){
    alertsList.forEach(alert => alerts.push(alert.textContent.trim()))
  }
  return alerts;
}

export const HtmlParser = (data, dataCheck = [])=>{
  const checkedData = {}
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(data, 'text/html');

  if(dataCheck.includes('alerts')){
    checkedData.alerts = getHtmlAlerts(htmlDoc);
  }
  return checkedData;
}

export const toBackendDateFormat = (date) => {
  let currentDate = ""
  if(date){
    const momentDate = moment(date).format("YYYY-MM-DD")
    if(momentDate !== "Invalid date"){
      currentDate = momentDate
    }
  }
  return currentDate
}

export const toFrontendDateFormat = (date = null, format = "DD/MM/YYYY") => {
  let currentDate = ""
  if(date){
    try {
      const momentDate = moment(date).format(format);
      if(momentDate !== "Invalid date"){
        currentDate = momentDate
      }
    }catch (e){
      console.error(e)
      currentDate = ""
    }
  }
  return currentDate
}

export const getComplianceDefaultValues = ({tags = [], compliance = []})=>{
  if(Array.isArray(tags) && Array.isArray(compliance) && tags.length && compliance.length){
    return tags.reduce(
        (previousValue, currentValue) => compliance.includes(currentValue.name) ?
            [...previousValue, currentValue.id]: previousValue,
        [])
  }

  return []
}

export const prepareDataToBackendFormat = (data = {}) => {
  Object.keys(data).forEach(key=> {
    if (key.toLowerCase().includes("date")) {
      data[key] = toBackendDateFormat(data[key])
    }
  })
  return data
}

export const isResponseOk = (response = {}) => (
    response?.status === 200 ||
    response?.status === 201 ||
    response?.statusText === "OK" ||
    response?.statusText === "Created" ||
    response?.ok
)

