import { createSlice } from '@reduxjs/toolkit';

import {getTags} from "../asyncServices/tagsService";

const initialState = {
    tags: [],
    lastUpdated: null,
    isLoading: 'initialLoading',
}

const tagsSlice = createSlice({
    name: 'tagsSlice',
    initialState,
    reducers: {
        setLastUpdated: (state, action) => {
            state.lastUpdated = action.payload
        },
        resetTagsState: () => ({...initialState})
    },
    extraReducers: (builder) => {
        builder.addCase(getTags.pending, (state) => {
            state.isLoading = 'getTags'
        })
        builder.addCase(getTags.fulfilled, (state, action) => {
            state.tags = action.payload
            state.isLoading = null
        })
    },
})

export const { setLastUpdated, resetTagsState } = tagsSlice.actions;
export default tagsSlice.reducer;
