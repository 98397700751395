import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import {useTableSearch} from "../../hooks";
import useCompaniesTable from "../../services/companies/companiesTableService";
import useTables from "../../services/tables/tablesService";
import useItem from "../../services/item/itemService";
import useModal from "../../hooks/useModal";
import useTags from "../../services/tags/tagsService";

import CompanyForm from "../company/components/CompanyForm";
import {
    TableWithWrapper,
    TableHeader,
    TranslationTypography,
    PageWrapper,
    ConfirmModal,
    AppModal
} from "../../components";
import {prepareDataToBackendFormat} from "../../utils/helpers";

export default function CompaniesPage () {

    const navigate = useNavigate();
    const [selectedId, setSelectId] = useState(null);
    const lastUpdated = useSelector(state => state.table.lastUpdated);
    const isLoading = useSelector(state => state.tags.isLoading);

    const { addItemData, deleteItemData } = useItem("/companies");
    const [getCompaniesTagsData, resetCompaniesTagsState] = useTags("/company");
    const [ getCompaniesData, setCompaniesTableSettings,,tableLastUpdated ] = useTables("/companies");
    const [open, handleOpen, handleClose] = useModal();
    const [openAdd, handleAddOpen, handleAddClose] = useModal();
    const {searchValue, setSearchValue} = useTableSearch();

    const onClickRow = (e, id) => navigate(`./${id}`);

    const onDelete = async (id) => {
        handleOpen()
        setSelectId(id)
    }

    const onCancelDelete = async () => {
        handleClose()
        setSelectId(null)
    }

    const onSubmitDelete = async () => {
        let response = null;
        if(selectedId) {
            response = await deleteItemData({id: selectedId})
            if (response){
                tableLastUpdated()
            }
        }
        return response
    }

    const onSubmitForm = async (values) => {
        const response = await addItemData({ data: prepareDataToBackendFormat(values)});
        if (response){
            tableLastUpdated()
        }
        return response
    };

    useEffect(()=>{
        getCompaniesData()
    }, [lastUpdated]);

    useEffect(()=>{
        getCompaniesTagsData();
        return () => resetCompaniesTagsState();
    }, [])

    const { companiesColumns } = useCompaniesTable({onDelete});

    return(
        <PageWrapper>
            <TranslationTypography text="Companies" variant='h3'/>
            <TableHeader onClick={handleAddOpen} value={searchValue} onChange={setSearchValue}/>
            <TableWithWrapper
                onClickRow={onClickRow}
                columns={companiesColumns}
                onTableSettingsChange={setCompaniesTableSettings}
                rowHover
            />
            <ConfirmModal
                title="modal.delete.company"
                open={open}
                onSubmit={onSubmitDelete}
                onClose={onCancelDelete}
            />
            <AppModal handleClose={handleAddClose} open={openAdd} title="modal.new.company">
                <CompanyForm isLoading={isLoading} onSubmitForm={onSubmitForm} onCancel={handleAddClose}/>
            </AppModal>
        </PageWrapper>
    )
};